import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext } from 'components/context'
import { ActionButton, SecondaryActionButton } from 'components/Buttons'
import { dateToLocale } from 'helpers'

const CancelOrFreeze = ({ goToConfirm, policy }) => {
  const { t, locale } = React.useContext(GlobalContext)

  if (!policy) return null
  if (!policy.membership) return null

  const endDate = dateToLocale(policy.membership.calculated_end_date, locale)

  return (
    <div>
      <h1>{ t('pages.cancellation.title') }</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t('pages.cancellation.info', { end_date: endDate }),
        }}
      />
      <Link to={'/membership/freeze'}>
        <ActionButton>
          { t('pages.cancellation.freeze_button') }
        </ActionButton>
      </Link>
      <SecondaryActionButton onClick={goToConfirm}>
        { t('pages.cancellation.cancel_button') }
      </SecondaryActionButton>
    </div>
  )
}

CancelOrFreeze.propTypes = {
  goToConfirm: PropTypes.func.isRequired,
  policy: PropTypes.object,
}

export default CancelOrFreeze
