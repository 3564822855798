import React from 'react'
import PropTypes from 'prop-types'
import { StandardErrorPage } from 'components/DefaultPages'

const FreezeError = ({ error, goBack }) =>
  <StandardErrorPage goBack={goBack} error={error} />

FreezeError.propTypes = {
  error: PropTypes.any.isRequired,
  goBack: PropTypes.func.isRequired,
}

export default FreezeError
