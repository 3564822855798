import React from 'react'
import { GlobalContext, UserContext } from 'components/context'

const Contact = () => {
  const { t } = React.useContext(GlobalContext)
  const { membership } = React.useContext(UserContext)

  if (!membership) return null

  return (
    <div>
      <h1>{ t('pages.contact.title') }</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t('pages.contact.info', { membership_number: membership.membership_number }),
        }}
      />
    </div>
  )
}

export default Contact
