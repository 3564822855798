import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GlobalContext, UserContext } from 'components/context'
import { ActionButton } from 'components/Buttons'
import { objByStringKey } from 'helpers'
import countries from './components/country'
import ModalVerification from './components/ModalVerification'
import moment from 'moment'
import bcrypt from 'bcryptjs'

const Divider = () =>
  <div className={'divider'}>&nbsp;</div>

const UpdateProfileView = ({ onChange, onSubmit, profile, validate }) => {
  const { t } = React.useContext(GlobalContext)
  const { post } = React.useContext(UserContext) || {}
  const { origin, pathname } = window.location

  // const [localProfile, setLocalProfile] = useState(profile)
  const [originalPrefix, setOriginalPrefix] = useState(profile.mobile?.prefix)
  const [originalNumber, setOriginalNumber] = useState(profile.mobile?.number)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [verification, setVerification] = useState({})
  const [verificationError, setVerificationError] = useState(null)

  const isPhoneChanged = () => {
    return (profile.mobile.prefix !== originalPrefix || profile.mobile.number !== originalNumber)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setVerificationError(null)

    if (validate && isPhoneChanged()) {
      try {
        const verify = await sendSmsToPhone()
        setVerification(verify)

        setIsModalOpen(true)
      } catch (err) {
        console.error(err)
      }
    } else {
      onSubmit(e)
    }
  }

  const onFocusOut = (e) => {
    if (e.target.name === 'email') {
      e.target.value = e.target.value.toLowerCase()
    } else if (e.target.name === 'mobile.number') {
      e.target.value = e.target.value.replaceAll(' ', '')
    } else if (e.target.name === 'mobile.prefix') {
      const validator = findValidator(e.target.value)
      document.getElementsByName('mobile.number')[0].setAttribute('pattern', validator)
    }
  }

  const prefixOptions = countries.map((c) => <option key={c.prefix} value={c.prefix}>{c.prefix}</option>)

  // get validation rule for phone number format
  const findValidator = (value) => {
    const country = countries.find((c) => c.prefix === value)
    return country ? country.validator : '^[0-9]{7,12}$'
  }

  // validate phone number format
  const checkNumber = (event) => {
    if (event.target.value.replaceAll(' ', '').match(event.target.pattern)) {
      event.target.setCustomValidity('')
    } else {
      handleInvalid(event)
    }
  }

  // handle displaying error message for invalid phone number format
  const handleInvalid = (event) => {
    const digits = event.target.pattern.split(/[{}]/)?.[1]?.replace(',', ' to ')
    event.target.setCustomValidity(t('pages.profile.phone_verification', { digits: digits }))
  }

  // handle confirm response from verification dialog
  const handleConfirm = (value) => {
    if (verification && verification.code) {
      const expiry = moment.utc(verification.code_expiry)
      if (expiry < moment()) {
        setVerificationError('pages.profile.verification.code_expired')
      }
      const checkValue = `${expiry.unix()}-${value}`
      // bcrypt code comes base64 encoded so it is handled as a string - atob() decodes
      if (bcrypt.compareSync(checkValue, atob(verification.code))) {
        // set the state phone number so we don't verify it again, or do verify if changed again
        setOriginalPrefix(profile.mobile.prefix)
        setOriginalNumber(profile.mobile.number)
        onSubmit(new Event('submit'))
      } else {
        setVerificationError('pages.profile.verification.invalid_code')
      }
    }
    setIsModalOpen(false)
  }

  // handle cancel response from verification dialog
  const handleCancel = () => {
    // TODO: is it possible to reset phone number?
    setIsModalOpen(false)
  }

  // send verification sms
  const sendSmsToPhone = async () => {
    const body = {
      domain: `${origin}${pathname}`,
      mobile: profile.mobile,
    }

    return await post('verifyPhone', body)
      .catch(err => console.log(err))
  }

  // text input fields
  const textField = (field, txt = null) =>
    <label className={field}>
      { txt ? txt.replace(/ /g, '\u00a0') : t(`forms.labels.${field}`) }
      <input
        type={'text'}
        name={field}
        defaultValue={objByStringKey(profile, field)}
        required
        onBlur={onFocusOut}
      />
    </label>

  // text input fields with validation and error
  const validatedField = (field, validator = null, txt = null, dep = null) =>
    <label className="{field} tooltip">
      { txt ? txt.replace(/ /g, '\u00a0') : t(`forms.labels.${field}`) }
      <input
        type={'text'}
        name={field}
        pattern={findValidator(objByStringKey(profile, dep))}
        defaultValue={objByStringKey(profile, field)}
        onInvalid={handleInvalid}
        onChange={validator}
        required
        onBlur={onFocusOut}
      />
      <span className="error"
        dangerouslySetInnerHTML={{ __html: verificationError ? t(verificationError) : '' }}
      ></span>
    </label>

  // select input field
  const selectField = (field, options = [], txt = null) =>
    <label className={field}>
      { txt ? txt.replace(/ /g, '\u00a0') : t(`forms.labels.${field}`) }
      <select
        name={field}
        defaultValue={objByStringKey(profile, field)}
        required
        onBlur={onFocusOut}
      >{options}</select>
    </label>

  return (
    <div id={'profile'}>
      <h1>{ t('pages.profile.title') }</h1>
      <div className={'full-width block'}>
        <form onChange={onChange} onSubmit={handleSubmit}>
          <div className={'form-grid'}>
            { textField('address.street') }
            { textField('address.postal_code') }
            <Divider />
            { textField('address.postal_location') }
            { selectField('mobile.prefix', prefixOptions, t('forms.labels.country')) }
            <Divider />
            { validatedField('mobile.number', checkNumber, t('forms.labels.mobile'), 'mobile.prefix') }
            { textField('email') }
          </div>
          <ActionButton type={'submit'}>
            { t('pages.profile.button') }
          </ActionButton>
        </form>
        <ModalVerification
          isOpen={isModalOpen}
          onClose={handleCancel}
          onConfirm={handleConfirm}
          verify={verification}
        />
      </div>
    </div>
  )
}

UpdateProfileView.propTypes = {
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  validate: PropTypes.bool,
}

export default UpdateProfileView
