import React from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'
import { GlobalContext, GlobalState, UserState } from './components/context'
import Login from 'scenes/Login'
import MyPage from 'scenes/MyPage'
import { ForgottenPassword, UpdatePassword } from './scenes/ResetPassword'
import LocaleSwitcher from './components/context/global/components/LocaleSwitcher'
import Invoice from './scenes/Invoice'

const NoSession = () => {
  const { theme } = React.useContext(GlobalContext)
  const className = `theme ${theme}`
  const logoPath = `/themes/${theme}/logo.png`

  const style = {
    margin: 'auto',
    maxWidth: '1024px',
  }

  return (
    <div className={className} style={style}>
      <LocaleSwitcher />
      <div id={'login'}>
        <a href={'/'}>
          <img src={logoPath} alt={'logo'} />
        </a>
        <Router>
          <Switch>
            <Route exact path={'/'} component={Login} />
            <Route path={'/password/reset'} component={ForgottenPassword} />
            <Route path={'/password/update'} component={UpdatePassword} />
            <Redirect from={'/'} to={'/'} />
          </Switch>
        </Router>
      </div>
    </div>
  )
}

const Main = (props) => {
  const { theme, token } = React.useContext(GlobalContext)
  const className = `theme ${theme}`

  if (!token) return <NoSession />

  return (
    <UserState>
      <MyPage className={className} {...props} />
    </UserState>
  )
}

const InvoiceWrapper = (props) => {
  const { token } = React.useContext(GlobalContext)

  if (!token) return <NoSession />

  return (
    <UserState>
      <Invoice { ...props } />
    </UserState>
  )
}

const App = () => {
  return (
    <GlobalState>
      <Router>
        <Switch>
          <Route exact path={'/'} component={Main} />
          <Route path={'/invoice/:id'} component={InvoiceWrapper} />
          <Route path={'/:page'} component={Main} />
        </Switch>
      </Router>
    </GlobalState>
  )
}

export default App
