import React from 'react'
import { UserContext } from 'components/context'

import IndexV1 from './v1'
import IndexV2 from './v2'

const Index = () => {
  const { membership: { operator: { id } } } = React.useContext(UserContext)

  const operatorsUsingV2 = [
    '848c0413-5e75-4f7a-87ad-e45a937c6318', // dev
    '5336003e-0105-4402-809f-93bf6498af34', // NO
    'c04bc7c3-903f-4804-a952-9abc0016b824', // DE
  ]

  const useV2 = operatorsUsingV2.includes(id)

  return useV2 ? IndexV2() : IndexV1()
}

export default Index
