import React from 'react'
import PropTypes from 'prop-types'
import { dateToLocale } from 'helpers'
import { ActionButton } from 'components/Buttons'
import { GlobalContext } from 'components/context'

const ConfirmReactivation = ({ onClick, membership }) => {
  const { t, locale } = React.useContext(GlobalContext)

  const endDate = dateToLocale(membership.end_date, locale)

  return (
    <div>
      <h1>{ t('pages.reactivate.title') }</h1>
      <p>{ t('pages.reactivate.info', { end_date: endDate }) }</p>
      <ActionButton onClick={onClick}>
        { t('pages.reactivate.confirm_button') }
      </ActionButton>
    </div>
  )
}

ConfirmReactivation.propTypes = {
  onClick: PropTypes.func.isRequired,
  membership: PropTypes.object.isRequired,
}

export default ConfirmReactivation
