import React from 'react'
import PropTypes from 'prop-types'

const List = ({ items }) => {
  const listStyle = {
    display: 'grid',
    gap: '1em',
    padding: '2em 0',
    gridTemplateColumns: '1fr 10fr',
  }

  const itemList = items.map(item => {
    return (
      <>
        <img src={item.imageURL} width="100px" alt=""/>
        <div>
          <a href={item.link}>{item.title}</a>
          <p>{item.body}</p>
        </div>
      </>
    )
  })

  return (
    <div style={listStyle}>{itemList}</div>
  )
}

List.propTypes = {
  items: PropTypes.array,
}

export default List
