import React from 'react'
import PropTypes from 'prop-types'
import {
  GlobalContext,
  UserContext,
} from 'components/context'
import { ActionButton } from 'components/Buttons'
import { StandardErrorPage, StandardPage } from 'components/DefaultPages'
import { useLogEvent } from 'components/hooks'
import { currentActiveRfidFromKeys } from 'helpers'

const Success = ({ success, t }) => {
  useLogEvent('new_chip_success')

  return (
    <StandardPage
      headerI18nKey={'pages.lost_chip.success.title'}
      type={'success'}
    >
      <p
        dangerouslySetInnerHTML={{
          __html: t('pages.lost_chip.success.info', success),
        }}
      />
    </StandardPage>
  )
}

Success.propTypes = {
  success: PropTypes.object,
  t: PropTypes.func,
}

const Index = ({ membership }) => {
  const { t } = React.useContext(GlobalContext)
  const { apiDelete } = React.useContext(UserContext)
  const [success, setSuccess] = React.useState(null)
  const [error, setError] = React.useState(null)
  useLogEvent('view_lost_chip')

  const goBack = () => {
    setError(null)
    setSuccess(null)
  }

  const rfid = currentActiveRfidFromKeys(membership.keys)
  const rfidCode = rfid ? rfid.code : null

  const onClick = () => {
    apiDelete('rfids', null, { code: rfidCode })
      .then(res => setSuccess(res))
      .catch(err => {
        console.error('rfid', error)
        setError(err)
      })
  }

  if (error) return <StandardErrorPage error={error} goBack={goBack}/>
  if (success) return <Success success={success} t={t} />

  // TODO: Fee should be looked up and provided as arg to the translation.
  return (
    <div>
      <h1>{ t('pages.lost_chip.title') }</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t('pages.lost_chip.info', { fee: '0' }),
        }}
      />
      <ActionButton onClick={onClick}>
        { t('pages.lost_chip.button') }
      </ActionButton>
    </div>
  )
}

Index.propTypes = {
  membership: PropTypes.object.isRequired,
}

export default Index
