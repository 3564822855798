export default {
  created_at: '2019-03-04 12:03:10 +0000',
  id: '66da916f-9823-43e1-9223-ea5a5bcc9367',
  name: 'EVO BUDDY Boost 4-7 mars 19 (3 mnd med 50%) +1 kartleggingstime (50)',
  promotion_type: 'referral',
  referrer_discount_months: 3,
  referrer_discount_percentage: 50,
  referrer_pt_hours: 1,
  sales_period_begins_at: '2019-03-04 00:00:00 +0000',
  sales_period_ends_at: null,
  signup_fee: 0,
  trial_days: null,
  updated_at: '2019-05-03 14:11:17 +0000',
  status: 'active',
  referrer_presentation: 'EVO BUDDY<br /><br />EVO verdsetter gode ambassadører og gir deg som medlem fordelene. Verver du nye medlemmer til EVO, belønner vi deg med 50 % på treningsavgiften i 2 måneder for hvert medlem du verver. For å få tilbudet må din venn melde seg inn med din vervekode.<br /><br />Nye medlemmer betaler kr 0,- for inneværende måned, samt halv pris i de to påfølgende måneder og får en kartleggingstime med PT (50 min). Innmeldingsavgift på kr 249,- tilkommer.',
}
