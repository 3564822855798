import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/context'
import { ActionButton } from 'components/Buttons'
import { Link } from 'react-router-dom'

const Button = ({ goBack, t }) => {
  if (goBack) {
    return (
      <ActionButton onClick={goBack}>
        { t('navigation.back') }
      </ActionButton>
    )
  }

  return (
    <Link to={'/'}>
      <ActionButton>
        { t('navigation.breadcrumb-mobile', { page: t('mypage.title') }) }
      </ActionButton>
    </Link>
  )
}

Button.propTypes = {
  goBack: PropTypes.func,
  t: PropTypes.func.isRequired,
}

const Info = ({ T, infoI18nKey, infoI18nVars }) => {
  if (!infoI18nKey) return null

  return (
    <p>
      <T
        t={infoI18nKey}
        vars={infoI18nVars}
      />
    </p>
  )
}

Info.propTypes = {
  T: PropTypes.func.isRequired,
  infoI18nKey: PropTypes.string,
  infoI18nVars: PropTypes.object,
}

const StandardPage = ({ children, type, headerI18nKey, ...rest }) => {
  const { t, T } = React.useContext(GlobalContext)

  if (!type) return null

  return (
    <div>
      <h1>{ t(headerI18nKey) }</h1>
      <Info T={T} {...rest} />
      { children }
      <Button t={t} {...rest} />
    </div>
  )
}

StandardPage.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string.isRequired,
  headerI18nKey: PropTypes.string.isRequired,
}

export default StandardPage
