import React from 'react'
import PropTypes from 'prop-types'
import { UserContext } from 'components/context'
import Consent from './Consent'
import { useLogEvent } from 'components/hooks'

const ConsentsWrapper = () => {
  const { membership } = React.useContext(UserContext)
  useLogEvent('view_consents', membership)

  if (!membership) return null

  return (
    <Consent membership={membership} />
  )
}

ConsentsWrapper.propTypes = {
  membership: PropTypes.object,
}

export default ConsentsWrapper
