import React from 'react'
import { GlobalContext, UserContext } from 'components/context'
import { ActionButton } from 'components/Buttons'
import { StandardErrorPage, StandardPage } from 'components/DefaultPages'
import { useLogEvent } from 'components/hooks'

const Success = () => {
  return (
    <StandardPage
      headerI18nKey={'pages.cancel_freeze.success.title'}
      infoI18nKey={'pages.cancel_freeze.success.info'}
      type={'success'}
    />
  )
}

const CancelFreeze = () => {
  const { t, T } = React.useContext(GlobalContext)
  const { apiResource, get, membership } = React.useContext(UserContext)
  const [policy, setPolicy] = React.useState()

  const [success, setSuccess] = React.useState(null)
  const [error, setError] = React.useState(null)

  useLogEvent('view_freeze_cancellation')

  React.useEffect(() => {
    console.debug('fetch policy')
    get('freeze_policy')
      .then(res => setPolicy(res))
      .catch(err => {
        console.error('err', err)
        setError(err)
      })
  }, [get, setError])

  if (!membership) return null
  if (!policy) return null

  const freezePeriod = policy.membership.scheduled_freeze || policy.membership.current_freeze

  const onCancel = () =>
    apiResource({ type: 'DELETE', subRoute: 'freeze_periods', id: freezePeriod.id })
      .then(res => setSuccess(res))
      .catch(err => {
        err.method = 'DELETE'
        setError(err)
      })

  const goBack = () => {
    setSuccess(null)
    setError(null)
  }

  if (error) return <StandardErrorPage error={error} goBack={goBack} />
  if (success) return <Success/>

  return (
    <div id={'cancel-freeze'}>
      <h1>{ t('pages.cancel_freeze.title') }</h1>
      <T
        t={'pages.cancel_freeze.info'}
      />
      <ActionButton onClick={onCancel}>
        { t('pages.cancel_freeze.button') }
      </ActionButton>
    </div>
  )
}

export default CancelFreeze
