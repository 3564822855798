import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

const Layout = ({ t, theme }) => {
  const favicon = `/themes/${theme}/favicon.png`

  return (
    <Helmet>
      <title>{ t('html_title') }</title>
      <link
        rel='shortcut icon'
        href={favicon}
        type='image/png'
      />
    </Helmet>
  )
}

Layout.propTypes = {
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
}

export default Layout
