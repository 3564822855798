import React from 'react'
import PropTypes from 'prop-types'
import { StandardPage } from 'components/DefaultPages'

const SuccessPage = ({ history }) => {
  const goBack = () => {
    (history.length > 3) ? history.go(1 - history.length) : history.goBack()
  }

  return (
    <StandardPage
      headerI18nKey={ 'pages.profile.success.title' }
      infoI18nKey={ 'pages.profile.success.info' }
      type={ 'success' }
      goBack={goBack}
    />
  )
}

SuccessPage.propTypes = {
  history: PropTypes.object.isRequired,
}

export default SuccessPage
