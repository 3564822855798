import React from 'react'
import PropTypes from 'prop-types'

const arrowDirections = {
  right: 'rotate(90deg)',
  left: 'rotate(270deg)',
  down: 'rotate(180deg)',
}

const IconArrow = props => {
  const style = props.style || {}

  const direction = arrowDirections[props.direction]

  if (direction) {
    style.transform = direction
  }
  return (
    <svg width='1em' height='1em' viewBox='0 0 24 24' style={style} {...props}>
      <path
        d='M15.498 15L17 13.614 12 9l-5 4.614L8.502 15 12 11.772z'
        fill={props.color}
      />
    </svg>
  )
}

IconArrow.propTypes = {
  style: PropTypes.object,
  direction: PropTypes.string,
  color: PropTypes.string,
}

export default IconArrow
