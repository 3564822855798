import mockFetch from './mock'

const prodFetch = (uri, opts) => {
  return fetch(process.env.REACT_APP_API + uri, opts)
}

export default (uri, opts) => {
  return process.env.REACT_APP_DEMO
    ? mockFetch(uri, opts)
    : prodFetch(uri, opts)
}
