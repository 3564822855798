import React from 'react'
import PropTypes from 'prop-types'
import { StandardPage } from 'components/DefaultPages'

const ActivationSuccess = ({ code }) => {
  return (
    <StandardPage
      headerI18nKey={'pages.activate_chip.success.title'}
      infoI18nKey={'pages.activate_chip.success.info'}
      infoI18nVars={{ code: code }}
      type={'success'}
    />
  )
}

ActivationSuccess.propTypes = {
  code: PropTypes.string.isRequired,
}

export default ActivationSuccess
