import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext, UserContext } from 'components/context'
import { SmallerTextActionButton, SmallerTextSecondaryActionButton } from 'components/Buttons'
import { dateToLocale } from 'helpers'

const CancellationGDPR = ({ cancelMembership }) => {
  const { t } = React.useContext(GlobalContext)
  const [gdprOptIn, setGdprOptIn] = React.useState(null)
  const [disabled, setDisabled] = React.useState(true)

  const onChange = (event) => {
    setGdprOptIn(event.target.value)
    setDisabled(false)
  }

  const onSubmit = () => cancelMembership(gdprOptIn)

  return (
    <div>
      <p>
        { t('pages.cancellation.gdpr.info')}
      </p>
      <form onChange={onChange} onSubmit={onSubmit}>
        <label>
          <input type={'radio'} id={'yes'} name={'yes'} value={true} />
          { t('pages.cancellation.gdpr.options.yes') }
        </label>
        <label>
          <input type={'radio'} id={'no'} name={'yes'} value={false} />
          { t('pages.cancellation.gdpr.options.no') }
        </label>
      </form>
      <SmallerTextActionButton onClick={onSubmit} disabled={disabled}>
        { t('pages.cancellation.cancel_button') }
      </SmallerTextActionButton>
    </div>
  )
}

CancellationGDPR.propTypes = {
  cancelMembership: PropTypes.func.isRequired,
}

const ConfirmCancellationView = ({ endDate, cancelMembership }) => {
  const { t, locale } = React.useContext(GlobalContext)
  return (
    <div>
      <h1>{ t('pages.cancellation.confirm.title') }</h1>

      <p
        dangerouslySetInnerHTML={{
          __html: t('pages.cancellation.confirm.info', { end_date: dateToLocale(endDate, locale) }),
        }}
      />

      <Link to={'/membership'}>
        <SmallerTextActionButton>
          { t('pages.cancellation.confirm.no_button') }
        </SmallerTextActionButton>
      </Link>
      <SmallerTextSecondaryActionButton onClick={cancelMembership}>
        { t('pages.cancellation.confirm.cancel_button') }
      </SmallerTextSecondaryActionButton>
    </div>
  )
}

ConfirmCancellationView.propTypes = {
  cancelMembership: PropTypes.func.isRequired,
  endDate: PropTypes.object.isRequired,
}

// A hack for Norway which has disabled cancellation through MyPage
// and requires the members to send them an email,
const NorwayConfirmCancellation = ({ membership }) => {
  const link = `mailto:utmelding@evofitness.no?subject=utmelding&body=Referanse: ${membership.membership_number}`

  return (
    <div>
      <h2>Er du helt sikker på at du vil avslutte medlemskapet?</h2>
      <p>
        Ved utmelding gjelder våre ordinære medlemsbetingelser,
        noe som innebærer en måneds betalende oppsigelse.
        Ønsker du å bevare treningshistorikk, adgangsbrikke og profil,
        anbefaler vi at du fryser medlemskapet.
      </p>
      <p>
        For utmelding, kontakt: <a href={link}>utmelding@evofitness.no</a>.
        Husk å oppgi e-postadresse du er registrert med (<strong>{ membership.profile.email }</strong>)
        eller din medlems-id (<strong>{ membership.membership_number }</strong>)
      </p>
    </div>
  )
}

NorwayConfirmCancellation.propTypes = {
  membership: PropTypes.object.isRequired,
}

const isNorwayAndNotPresaleOrTrial = (membership) =>
  membership.operator.id === '5336003e-0105-4402-809f-93bf6498af34' &&
    !['in_trial', 'presale'].includes(membership.status)

const ConfirmCancellation = ({ cancelMembership, policy }) => {
  const { t } = React.useContext(GlobalContext)
  const { membership } = React.useContext(UserContext)
  const [gdpr, setGDPR] = React.useState(false)
  if (!policy) return null
  if (!membership) return null

  if (isNorwayAndNotPresaleOrTrial(membership)) {
    return <NorwayConfirmCancellation membership={membership} />
  }

  const endDate = policy.membership.calculated_end_date

  const cancelMembershipFunction = t('pages.cancellation.gdpr.info') !== 'pages.cancellation.gdpr.info' ? cancelMembership : () => setGDPR(true)

  if (gdpr) {
    return (
      <CancellationGDPR
        cancelMembership={cancelMembership}
      />
    )
  }

  return (
    <ConfirmCancellationView
      endDate={endDate}
      cancelMembership={cancelMembershipFunction}
    />
  )
}

ConfirmCancellation.propTypes = {
  cancelMembership: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
  policy: PropTypes.object,
}

export default ConfirmCancellation

export {
  ConfirmCancellationView,
}
