import React from 'react'
import { StandardPage } from 'components/DefaultPages'

const ConsentSuccess = () =>
  <StandardPage
    headerI18nKey={'pages.consents.success.title'}
    infoI18nKey={'pages.consents.success.info'}
    type={'success'}
  />

export default ConsentSuccess
