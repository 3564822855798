import React, { useEffect } from 'react'
import { GlobalContext, UserContext } from 'components/context'

const ChangeCreditCard = () => {
  const { verifoneUrl } = React.useContext(UserContext)
  const { t } = React.useContext(GlobalContext)

  useEffect(() => {
    verifoneUrl()
      .then(iframeSrc => {
        const script = document.createElement('script')
        script.src = iframeSrc.url
        script.defer = true
        document.querySelector('#payment_form_container').appendChild(script)

        return () => {
          document.querySelector('#payment_form_container').removeChild(script)
        }
      })
  }, [verifoneUrl])

  // TODO: Use Array with translations keys while waiting for the preferred translation to get done
  return (
    <>
      <h1>{ t(['pages.credit-card.title', 'pages.payment.credit_card.update_link']) }</h1>
      <div className='credit-card-container'>
        <div id='payment_form_container' />
      </div>
    </>
  )
}

export default ChangeCreditCard
