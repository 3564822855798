import React, { useRef, useContext, useEffect, useState } from 'react'
import { GlobalContext } from 'components/context'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'

const PTPackage = ({ onEnd, onStart }) => {
  const { locale } = useContext(GlobalContext)

  window.sessionStorage.setItem(
    'pts-token',
    window.localStorage.getItem('token'))

  const ref = useRef(null)
  useEffect(() => {
    const el = ref.current
    el.addEventListener('start', onStart)
    el.addEventListener('end', onEnd)

    return () => {
      el.removeEventListener('start', onStart)
      el.removeEventListener('end', onEnd)
    }
  })

  const [session, forceUpdate] = useState(1)
  const location = useLocation()

  useEffect(() => {
    forceUpdate(session => session + 1)
  }, [location, forceUpdate])

  return (
    <div id={'PTPackage'}>
      <pt-package-buy session={session} ref={ref} locale={locale}></pt-package-buy>
    </div>
  )
}

PTPackage.propTypes = {
  onEnd: PropTypes.func,
  onStart: PropTypes.func,
}

export default PTPackage
