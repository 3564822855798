import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const addressTxt = ({ address }) =>
  `${address.street}, ${address.postal_code} ${address.postal_location}`

const name = (profile) =>
  `${profile.first_name} ${profile.last_name}`

const mobile = ({ mobile }) =>
  `${mobile.prefix} ${mobile.number}`

const ProfileContent = ({ profile }) => {
  if (!profile) return null

  return (
    <ul>
      <li>{ name(profile) }</li>
      <li>{ addressTxt(profile) }</li>
      <li>{ profile.email }</li>
      <li>{ mobile(profile) }</li>
    </ul>
  )
}

ProfileContent.propTypes = {
  profile: PropTypes.object,
}

const UpdateProfileLink = ({ t, match }) => {
  const visible = t('pages.membership.profile.link') !== 'pages.membership.profile.link'

  if (!visible) return null

  return (
    <p>
      <Link to={`${match.path}/profile`} data-cy={'profile'}>
        { t('pages.membership.profile.link') }
      </Link>
    </p>
  )
}

UpdateProfileLink.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
}

const Profile = ({ match, profile, t }) => {
  return (
    <div className={'profile'}>
      <h2>{ t('pages.membership.profile.title') }</h2>
      <ProfileContent profile={profile} />
      <UpdateProfileLink match={match} t={t} />
    </div>
  )
}

Profile.propTypes = {
  match: PropTypes.object.isRequired,
  profile: PropTypes.object,
  t: PropTypes.func.isRequired,
}

export default Profile
