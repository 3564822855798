const headers = (token) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  if (token) headers.Authorization = token

  return headers
}

const options = ({ method, body = null, token = null }) => {
  const opts = {
    headers: headers(token),
    method: method,
  }

  if (body) opts.body = JSON.stringify(body)

  return opts
}

class APIError extends Error {
  constructor (status, errorObj, reason = null) {
    super(status)
    this.status = status
    this.errors = errorObj
    this.reason = reason
    this.name = 'APIError'
  }
}

const result = (res) => {
  if (res.ok) {
    if (res.statusText === 'No Content') {
      return Promise.resolve({})
    }
    return res.json()
  }

  return res.json()
    // catch JSON parse error for non json results
    .catch(_ => {
      return {
        status: res.status,
        errors: [{ type: res.status, message: res.statusText, url: res.url }],
      }
    })
    .then(e => {
      if (e.message === 'RegisterCreditCard::Main::CreditCardAlreadyExistsForCheckoutError') {
        return {
          status: 200,
          message: e.message,
        }
      }
      return Promise.reject(new APIError(res.status, e.errors, e.reason))
    })
}

const routes = {
  auth: '/auth/authenticate',
  membership: '/v2/membership',
  referral: '/v1/current_referral',
  scheduled_invoice: '/v1/membership/invoices/next',
  invoices: '/v1/membership/invoices',
  // workouts: '/v1/membership/workouts',
  workouts: '/v2/workouts',
  freeze_policy: '/v1/membership/policies/freeze',
  freeze_periods: '/v1/membership/freeze_periods',
  cancellation_policy: '/v1/membership/policies/cancellation',
  cancellations: '/v1/membership/cancellations',
  rfids: '/v1/membership/rfids',
  gdpr: '/v1/membership/gdpr',
  event: '/v1/membership/event',
  resetPassword: '/auth/reset',
  updatePassword: '/auth/update',
  updateProfile: '/v1/membership/profile',
  locations: '/v1/membership/locations',
  updateLocation: '/v1/membership/update-location',
  refundableInvoices: '/v1/membership/invoices/refundable',
  verifyPhone: '/v1/membership/phone_verify',
}

export {
  options,
  result,
  routes,
}
