import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/context'

const Terms = ({ product }) => {
  const { t } = React.useContext(GlobalContext)
  const termsLink = t('pages.membership.terms.standard-terms-link')

  return (
    <div>
      <h2>{ t('pages.membership.terms.title') }</h2>
      <p style={{ lineHeight: '1.63', whiteSpace: 'pre-line' }}>
        { product ? product.post_signup_presentation : '' }
      </p>
      <p dangerouslySetInnerHTML={{ __html: termsLink }}/>
    </div>
  )
}

Terms.propTypes = {
  product: PropTypes.object,
}

export default Terms
