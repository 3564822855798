import React from 'react'
import { Link } from 'react-router-dom'
import { ActionButton } from 'components/Buttons'
import { GlobalContext } from 'components/context'
import { useLogEvent } from 'components/hooks'
import Icon from 'components/icons'

// TODO: wrong icon on list items
const MissingOutOn = () => {
  useLogEvent('view_cancellation_missing_out_on')

  const { t } = React.useContext(GlobalContext)
  const listI18NKeys = ['one', 'two', 'three', 'four', 'five']

  const listStyle = {
    display: 'grid',
    gap: '1em',
    padding: '2em 0',
    'grid-template-columns': '1fr 10fr',
  }

  return (
    <div>
      <h1>{ t('pages.cancellation.missing_out_on.title') }</h1>
      <div style={listStyle}>
        { listI18NKeys.map(key => {
          return (
            <>
              <Icon type="mail" />
              <div>
                <b>{ t(`pages.cancellation.missing_out_on.${key}.title`) }</b>
                <p>{ t(`pages.cancellation.missing_out_on.${key}.body`) }</p>
              </div>
            </>
          )
        })}
      </div>
      <Link to={'/membership/cancellation/confirm'}>
        <ActionButton>
          { t('pages.cancellation.missing_out_on.next') }
        </ActionButton>
      </Link>
    </div>
  )
}

export default MissingOutOn
