import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext, UserContext } from 'components/context'
import { ActionButton, SecondaryActionButton } from 'components/Buttons'
import { dateToLocale } from 'helpers'
import { StandardErrorPage } from 'components/DefaultPages'
import { useLogEvent } from 'components/hooks'

const CancelOrFreeze = () => {
  useLogEvent('view_cancellation_freeze_instead?')

  const { t, locale } = React.useContext(GlobalContext)
  const { get } = React.useContext(UserContext)
  const [policy, setPolicy] = React.useState()
  const [error, setError] = React.useState(null)
  const goBack = () => setError(null)

  React.useEffect(() => {
    get('cancellation_policy')
      .then(res => setPolicy(res))
      .catch(err => {
        console.error('policy:', err)
        setError(err)
      })
  }, [get])

  if (error) return <StandardErrorPage error={error} goBack={goBack} />

  if (!policy) return null
  if (!policy.membership) return null

  const endDate = dateToLocale(policy.membership.calculated_end_date, locale)

  return (
    <div>
      <h1>{ t('pages.cancellation.title') }</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t('pages.cancellation.info', { end_date: endDate }),
        }}
      />
      <Link to={'/membership/freeze'}>
        <ActionButton>
          { t('pages.cancellation.freeze_button') }
        </ActionButton>
      </Link>
      <Link to="/membership/cancellation/tips" className="no-underline">
        <SecondaryActionButton>
          { t('pages.cancellation.cancel_button') }
        </SecondaryActionButton>
      </Link>
    </div>
  )
}

CancelOrFreeze.propTypes = {
  goToConfirm: PropTypes.func.isRequired,
  policy: PropTypes.object,
}

export default CancelOrFreeze
