import moment from 'moment'
import bcrypt from 'bcryptjs'

export const verify = (body) => {
  const codeExpiry = moment().add(1, 'hours')
  const expiryS = codeExpiry.unix()

  const code = Math.floor(Math.random() * (999999 - 100000 + 1) + 100000)
  console.info('code:', code)
  const codeB = bcrypt.hashSync(`${expiryS}-${code}`, bcrypt.genSaltSync(10))

  return {
    code: btoa(codeB),
    code_expiry: codeExpiry.format(),
  }
}
