export default [
  {
    invoice: {
      id: '254678fd-0e34-4e84-a4eb-dff14625fd6c',
      invoice_date: '2020-12-01',
      period: { from: '2020-12-01', to: '2020-12-31' },
      amount: 10000,
      oid: 1235,
      currency: 'NOK',
      payments: [{
        created_at: '2020-12-01',
        status: 'accepted',
      }],
    },
    closed_period: { from: '2020-11-10', to: '2020-11-30' },
    closed_days: 21,
    refundable_amount: 7000,
  },
  {
    invoice: {
      id: '61ab0b9a-1a7d-4cdd-90b0-502154ef3a32',
      invoice_date: '2020-04-01',
      period: { from: '2020-04-01', to: '2020-04-30' },
      amount: 10000,
      oid: 1234,
      currency: 'NOK',
      payments: [{
        created_at: '2020-04-01',
        status: 'accepted',
      }],
    },
    closed_period: { from: '2020-04-01', to: '2020-04-30' },
    closed_days: 30,
    refundable_amount: 10000,
  },
]
