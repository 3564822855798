import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext, UserContext } from 'components/context'
import { ErrorPage, SuccessPage } from './components'
import UpdateProfileView from './UpdateProfileView'

const UpdateProfile = ({ history }) => {
  const { t } = React.useContext(GlobalContext)
  const { membership, patch } = React.useContext(UserContext)

  const [profile, setProfile] = React.useState(membership ? membership.profile : null)
  const [success, setSuccess] = React.useState(false)
  const [error, setError] = React.useState(false)

  if (!membership) return null

  const onChange = (e) => {
    const { value, name } = e.target
    const objKey = name.split('.')

    if (objKey.length > 1) {
      const subObj = Object.assign(profile[objKey[0]], { [objKey[1]]: value })
      setProfile(Object.assign(profile, { [objKey[0]]: subObj }))
    } else {
      setProfile(Object.assign(profile, { [name]: value }))
    }
  }

  const onSubmit = (e) => {
    e.preventDefault()
    // We have to delete keys that aren't allowed by the API
    delete profile.name
    delete profile.birthdate
    delete profile.gender
    patch('updateProfile', profile)
      .then(setSuccess)
      .catch(setError)
  }

  const goBack = () => {
    setSuccess(false)
    setError(false)
  }

  if (success) return <SuccessPage history={history} />
  if (error) return <ErrorPage error={error} goBack={goBack} />

  return (
    <UpdateProfileView
      onChange={onChange}
      onSubmit={onSubmit}
      profile={profile}
      validate={!!membership?.product?.require_phone_verification}
      id={membership.id}
      t={t}
    />
  )
}

UpdateProfile.propTypes = {
  history: PropTypes.object.isRequired,
}

export default UpdateProfile
