import React from 'react'
import CancelOrFreeze from './CancelOrFreeze'
import ConfirmCancellation from './ConfirmCancellation'
import { StandardErrorPage, StandardPage } from 'components/DefaultPages'
import { dateToLocale } from 'helpers'
import { GlobalContext, UserContext } from 'components/context'
import { useLogEvent } from 'components/hooks'

const Index = () => {
  const { locale } = React.useContext(GlobalContext)
  const { get, post } = React.useContext(UserContext)
  const [confirm, setConfirm] = React.useState(false)
  const [success, setSuccess] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [policy, setPolicy] = React.useState()

  useLogEvent('view_cancel_membership')

  React.useEffect(() => {
    get('cancellation_policy')
      .then(res => setPolicy(res))
      .catch(err => {
        console.error('cancel:', err)
        setError(err)
      })
  }, [get])

  const goBack = () => {
    setSuccess(null)
    setError(null)
  }

  const cancelMembership = (gdprOptIn = null) => {
    // For some reason gdprOptIn is a class when not provided,
    // so we explicitly check if it is a boolean
    const body = typeof gdprOptIn === 'boolean' ? { gdpr_opt_in: gdprOptIn } : null
    post('cancellations', body)
      .then(res => setSuccess(res))
      .catch(err => setError(err))
  }

  if (error) return <StandardErrorPage error={error} goBack={goBack} />
  if (success) {
    return (
      <StandardPage
        headerI18nKey={'pages.cancellation.success.title'}
        type={'success'}
        infoI18nKey={'pages.cancellation.success.info'}
        infoI18nVars={{ date: dateToLocale(success.end_date, locale) }}
      />
    )
  }

  if (confirm) {
    return (
      <ConfirmCancellation
        cancelMembership={cancelMembership}
        setError={setError}
        policy={policy}
      />
    )
  }

  return (
    <CancelOrFreeze goToConfirm={() => setConfirm(true)} policy={policy} />
  )
}

export default Index
