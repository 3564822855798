import React from 'react'
import { GlobalContext, UserContext } from 'components/context'
import { amountToCurrency, dateToLocale, statusIsInactive } from 'helpers'

const NextPayment = () => {
  const { t, locale } = React.useContext(GlobalContext)
  const { get, membership } = React.useContext(UserContext)
  const [scheduled, setScheduled] = React.useState()

  React.useEffect(() => {
    if (!membership || statusIsInactive(membership.status)) return
    console.debug('fetch next payment')
    get('scheduled_invoice')
      .then(res => {
        setScheduled({ ...res, currency: membership.operator.currency })
      })
      .catch(error => console.error('fetch next payment:', error))
  }, [get, membership])

  if (!scheduled) return null

  const translationVars = {
    date: dateToLocale(scheduled.invoice_date, locale),
    amount: amountToCurrency(scheduled.amount, locale, scheduled.currency),
  }

  return (
    <>
      <h2 style={{ marginTop: '40px' }}>
        { t('pages.payment.scheduled.title') }
      </h2>
      <p
        dangerouslySetInnerHTML={
          { __html: t('pages.payment.scheduled.text', translationVars) }
        }
      />
    </>
  )
}

export default NextPayment
