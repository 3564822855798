const countries = [
  { prefix: '+1', country: 'North America', whitelisted: true, validator: '/^[0,9]{10}$/' },
  { prefix: '+20', country: 'Egypt', code: 'EG' },
  { prefix: '+211', country: 'South Sudan', code: 'SS' },
  { prefix: '+212', country: 'Morocco', code: 'MA' },
  { prefix: '+213', country: 'Algeria', code: 'DZ' },
  { prefix: '+216', country: 'Tunisia', code: 'TN' },
  { prefix: '+218', country: 'Libya', code: 'LY' },
  { prefix: '+220', country: 'Gambia', code: 'GM' },
  { prefix: '+221', country: 'Senegal', code: 'SN' },
  { prefix: '+222', country: 'Mauritania', code: 'MR' },
  { prefix: '+223', country: 'Mali', code: 'ML' },
  { prefix: '+224', country: 'Guinea', code: 'GN' },
  { prefix: '+225', country: 'Côte d\'Ivoire', code: 'CI' },
  { prefix: '+226', country: 'Burkino Faso', code: 'BF' },
  { prefix: '+227', country: 'Niger', code: 'NE' },
  { prefix: '+228', country: 'Togo', code: 'TG' },
  { prefix: '+229', country: 'Benin', code: 'BJ' },
  { prefix: '+230', country: 'Mauritius', code: 'MU' },
  { prefix: '+231', country: 'Liberia', code: 'LR' },
  { prefix: '+232', country: 'Sierra Leona', code: 'SL' },
  { prefix: '+233', country: 'Ghana', code: 'GH' },
  { prefix: '+234', country: 'Nigeria', code: 'NG' },
  { prefix: '+235', country: 'Chad', code: 'TD' },
  { prefix: '+236', country: 'Central African Republic', code: 'CF' },
  { prefix: '+237', country: 'Cameroon', code: 'cM' },
  { prefix: '+238', country: 'Cabo Verde', code: 'CV' },
  { prefix: '+239', country: 'São Tomé and Príncipe', code: 'ST' },
  { prefix: '+240', country: 'Equatorial Guinea', code: 'GQ' },
  { prefix: '+241', country: 'Gabon', code: 'GA' },
  { prefix: '+242', country: 'Republic of the Congo', code: 'CG' },
  { prefix: '+243', country: 'Democratic Repbulic of the Congo', code: 'CD' },
  { prefix: '+244', country: 'Angola', code: 'AO' },
  { prefix: '+245', country: 'Guinea-Bissau', code: 'GW' },
  { prefix: '+246', country: 'British Indian Ocean Territory', code: 'IO' },
  { prefix: '+247', country: 'Ascencion Island', code: 'AC' },
  { prefix: '+248', country: 'Seychelles', code: 'SC' },
  { prefix: '+249', country: 'Sudan', code: 'SD' },
  { prefix: '+250', country: 'Rwanda', code: 'RW' },
  { prefix: '+251', country: 'Ethiopia', code: 'ET' },
  { prefix: '+252', country: 'Somalia', code: 'SO' },
  { prefix: '+253', country: 'Djibouti', code: 'DJ' },
  { prefix: '+254', country: 'Kenya', code: 'KE' },
  { prefix: '+255', country: 'Tanzania', code: 'TZ' },
  { prefix: '+256', country: 'Uganda', code: 'UG' },
  { prefix: '+257', country: 'Burundi', code: 'BI' },
  { prefix: '+258', country: 'Mozambique', code: 'MZ' },
  { prefix: '+260', country: 'Zambia', code: 'ZM' },
  { prefix: '+261', country: 'Madagascar', code: 'MG' },
  { prefix: '+262', country: 'Réunion', code: 'RE' },
  { prefix: '+263', country: 'Zimbabwe', code: 'ZW' },
  { prefix: '+264', country: 'Namibia', code: 'NA' },
  { prefix: '+265', country: 'Malawi', code: 'MW' },
  { prefix: '+266', country: 'Lesotho', code: 'LS' },
  { prefix: '+267', country: 'Botswana', code: 'BW' },
  { prefix: '+268', country: 'Eswatini', code: 'SZ' },
  { prefix: '+269', country: 'Comoros', code: 'KM' },
  { prefix: '+27', country: 'South Africa', code: 'ZA' },
  { prefix: '+290', country: 'Saint Helena', code: 'SH' },
  { prefix: '+291', country: 'Eritrea', code: 'ER' },
  { prefix: '+297', country: 'Aruba', code: 'AW' },
  { prefix: '+298', country: 'Faroe Islands', code: 'FO', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+299', country: 'Greenland', code: 'GL', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+30', country: 'Greece', code: 'GR', whitelisted: true, validator: '^[0-9]{10}$' },
  { prefix: '+31', country: 'Netherlands', code: 'NL', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+32', country: 'Belgium', code: 'BE', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+33', country: 'France', code: 'FR', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+34', country: 'Spain', code: 'ES', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+350', country: 'Gibraltar', code: 'GI', whitelisted: true, validator: '^[0-9]{7,10}$' },
  { prefix: '+351', country: 'Portugal', code: 'PT', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+352', country: 'Luxembourg', code: 'LU', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+353', country: 'Ireland', code: 'IE', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+354', country: 'Iceland', code: 'IS', whitelisted: true, validator: '^[0-9]{7,10}$' },
  { prefix: '+355', country: 'Albania', code: 'AL', whitelisted: true, validator: '^[0-9]{7,10}$' },
  { prefix: '+356', country: 'Malta', code: 'MT', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+357', country: 'Cyprus', code: 'CY', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+358', country: 'Finland', code: 'FI', whitelisted: true, validator: '^[0-9]{8,10}$' },
  { prefix: '+359', country: 'Bulgaria', code: 'BG', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+36', country: 'Hungary', code: 'HU', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+370', country: 'Lithuania', code: 'LT', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+371', country: 'Latvia', code: 'LV', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+372', country: 'Estonia', code: 'EE', whitelisted: true, validator: '^[0-9]{7,8}$' },
  { prefix: '+373', country: 'Moldova', code: 'MD', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+374', country: 'Armenia', code: 'AM', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+375', country: 'Belarus', code: 'BY', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+376', country: 'Andorra', code: 'AD', whitelisted: true, validator: '^[0-9]{7,9}$' },
  { prefix: '+377', country: 'Monaco', code: 'MC', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+378', country: 'San Marino', code: 'SM', whitelisted: true, validator: '^[0-9]{7,10}$' },
  { prefix: '+379', country: 'Holy See', code: 'VA', whitelisted: true, validator: '^[0-9]{7,10}$' },
  { prefix: '+380', country: 'Ukraine', code: 'UA', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+381', country: 'Serbia', code: 'RS', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+382', country: 'Montenegro', code: 'ME', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+383', country: 'Kosovo', code: 'XK', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+385', country: 'Croatia', code: 'HR', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+386', country: 'Slovenia', code: 'SI', whitelisted: true, validator: '^[0-9]{7,10}$' },
  { prefix: '+387', country: 'Bosnia and Herzogovina', code: 'BA', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+389', country: 'North Macedonia', code: 'MK', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+39', country: 'Italy', code: 'IT', whitelisted: true, validator: '^[0-9]{10}$' },
  { prefix: '+40', country: 'Romania', code: 'RO', whitelisted: true, validator: '^[0-9]{10}$' },
  { prefix: '+41', country: 'Switzerland', code: 'CH', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+420', country: 'Czechia', code: 'CZ', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+421', country: 'Slovakia', code: 'SK', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+423', country: 'Liechtenstein', code: 'LI', whitelisted: true, validator: '^[0-9]{7}$' },
  { prefix: '+43', country: 'Austria', code: 'AT', whitelisted: true, validator: '^[0-9]{10}$' },
  { prefix: '+44', country: 'United Kingdom', code: 'GB', whitelisted: true, validator: '^[0-9]{10}$' },
  { prefix: '+45', country: 'Denmark', code: 'DK', whitelisted: true, validator: '^[0-9]{8}$' },
  { prefix: '+46', country: 'Sweden', code: 'SE', whitelisted: true, validator: '^[0-9]{7,10}$' },
  { prefix: '+47', country: 'Norway', code: 'NO', whitelisted: true, validator: '^\\d{8}$' },
  { prefix: '+48', country: 'Poland', code: 'PL', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+49', country: 'Germany', code: 'DE', whitelisted: true, validator: '^[0-9]{10,11}$' },
  { prefix: '+500', country: 'Falkland Islands', code: 'FK' },
  { prefix: '+501', country: 'Belize', code: 'BZ' },
  { prefix: '+502', country: 'Guatemala', code: 'GT' },
  { prefix: '+503', country: 'El Salvador', code: 'SV' },
  { prefix: '+504', country: 'Honduras', code: 'HN' },
  { prefix: '+505', country: 'Nicaragua', code: 'NI' },
  { prefix: '+506', country: 'Costa Rica', code: 'CR' },
  { prefix: '+507', country: 'Panama', code: 'PA' },
  { prefix: '+508', country: 'Saint-Pierre and Miquelon', code: 'FR' },
  { prefix: '+509', country: 'Haiti', code: 'HT' },
  { prefix: '+51', country: 'Peru', code: 'PE' },
  { prefix: '+52', country: 'Mexico', code: 'MX', whitelisted: true, validator: '^[0-9]{10}$' },
  { prefix: '+53', country: 'Cuba', code: 'CU' },
  { prefix: '+54', country: 'Argentina', code: 'AR' },
  { prefix: '+55', country: 'Brazil', code: 'BR' },
  { prefix: '+56', country: 'Chile', code: 'CL' },
  { prefix: '+57', country: 'Colombia', code: 'CO' },
  { prefix: '+58', country: 'Venezuela', code: 'VE' },
  { prefix: '+590', country: 'Guadeloupe', code: 'GP' },
  { prefix: '+591', country: 'Bolivia', code: 'BO' },
  { prefix: '+592', country: 'Guyana', code: 'GY' },
  { prefix: '+593', country: 'Ecuador', code: 'EC' },
  { prefix: '+594', country: 'French Guiana', code: 'GF' },
  { prefix: '+595', country: 'Paraguay', code: 'PY' },
  { prefix: '+596', country: 'Martinique', code: 'MQ' },
  { prefix: '+597', country: 'Suriname', code: 'SR' },
  { prefix: '+598', country: 'Uruguay', code: 'UY' },
  { prefix: '+599', country: 'Netherland Antilles', code: 'AN' },
  { prefix: '+60', country: 'Malaysia', code: 'MY' },
  { prefix: '+61', country: 'Australia', code: 'AU', whitelisted: true, validator: '^[0-9]{9}$' },
  { prefix: '+62', country: 'Indonesia', code: 'ID' },
  { prefix: '+63', country: 'Philippines', code: 'PH' },
  { prefix: '+64', country: 'New Zealand', code: 'NZ', whitelisted: true, validator: '^[0-9]{8,10}$' },
  { prefix: '+65', country: 'Singapore', code: 'SG' },
  { prefix: '+66', country: 'Thailand', code: 'TH' },
  { prefix: '+670', country: 'Timor-Leste', code: 'TL' },
  { prefix: '+672', country: 'Australian External Territories', code: 'AU' },
  { prefix: '+673', country: 'Brunei', code: 'BN' },
  { prefix: '+674', country: 'Nauru', code: 'NR' },
  { prefix: '+675', country: 'Papau New Guinea', code: 'PG' },
  { prefix: '+676', country: 'Tonga', code: 'TO' },
  { prefix: '+677', country: 'Solomon Islands', code: 'SB' },
  { prefix: '+678', country: 'Vanuatu', code: 'VU' },
  { prefix: '+679', country: 'Fiji', code: 'FJ' },
  { prefix: '+680', country: 'Palau', code: 'PW' },
  { prefix: '+681', country: 'Wallis and Futuna', code: 'WF' },
  { prefix: '+682', country: 'Cook Islands', code: 'CK' },
  { prefix: '+683', country: 'Niue', code: 'NU' },
  { prefix: '+685', country: 'Samoa', code: 'WS' },
  { prefix: '+686', country: 'Kiribati', code: 'KI' },
  { prefix: '+687', country: 'New Caledonia', code: 'NC' },
  { prefix: '+688', country: 'Tuvalu', code: 'TV' },
  { prefix: '+689', country: 'French Polynesia', code: 'PF' },
  { prefix: '+690', country: 'Tokelau', code: 'TK' },
  { prefix: '+691', country: 'Federated States of Micronesia', code: 'FM' },
  { prefix: '+692', country: 'Marshall Islands', code: 'MH' },
  { prefix: '+7', country: 'Russia', code: 'RU' },
  { prefix: '+81', country: 'Japan', code: 'JP' },
  { prefix: '+82', country: 'South Korea', code: 'KR' },
  { prefix: '+84', country: 'Viet Nam', code: 'VN' },
  { prefix: '+850', country: 'North Korea', code: 'KP' },
  { prefix: '+852', country: 'Hong Kong', code: 'HK' },
  { prefix: '+853', country: 'Macao', code: 'MO' },
  { prefix: '+855', country: 'Cambodia', code: 'KH' },
  { prefix: '+856', country: 'Laos', code: 'LA' },
  { prefix: '+86', country: 'China', code: 'CN' },
  { prefix: '+880', country: 'Bangladesh', code: 'BD' },
  { prefix: '+886', country: 'Taiwan', code: 'TW' },
  { prefix: '+90', country: 'Türkiye', code: 'TR' },
  { prefix: '+91', country: 'India', code: 'IN' },
  { prefix: '+92', country: 'Pakistan', code: 'PK' },
  { prefix: '+93', country: 'Afghanistan', code: 'AF' },
  { prefix: '+94', country: 'Sri Lanka', code: 'LK' },
  { prefix: '+95', country: 'Myanmar', code: 'MM' },
  { prefix: '+960', country: 'Maldives', code: 'MV' },
  { prefix: '+961', country: 'Lebanon', code: 'LB' },
  { prefix: '+962', country: 'Jordan', code: 'JO' },
  { prefix: '+963', country: 'Syria', code: 'SY' },
  { prefix: '+964', country: 'Iraq', code: 'IQ' },
  { prefix: '+965', country: 'Kuwait', code: 'KW' },
  { prefix: '+966', country: 'Saudi Arabia', code: 'SA' },
  { prefix: '+967', country: 'Yemen', code: 'YE' },
  { prefix: '+968', country: 'Oman', code: 'OM' },
  { prefix: '+970', country: 'Palestine', code: 'PS' },
  { prefix: '+971', country: 'United Arab Emirates', code: 'AE' },
  { prefix: '+972', country: 'Israel', code: 'IL' },
  { prefix: '+973', country: 'Bahrain', code: 'BH' },
  { prefix: '+974', country: 'Qatar', code: 'QA' },
  { prefix: '+975', country: 'Bhutan', code: 'BT' },
  { prefix: '+976', country: 'Mongolia', code: 'MN' },
  { prefix: '+977', country: 'Nepal', code: 'NP' },
  { prefix: '+98', country: 'Iran', code: 'IR' },
  { prefix: '+992', country: 'Tajikistan', code: 'TJ' },
  { prefix: '+993', country: 'Turkmenistan', code: 'TM' },
  { prefix: '+994', country: 'Azerbaijan', code: 'AZ' },
  { prefix: '+995', country: 'Georgia', code: 'GE' },
  { prefix: '+996', country: 'Kyrgyzstan', code: 'KG' },
  { prefix: '+997', country: 'Kazakhstan', code: 'KZ' },
  { prefix: '+998', country: 'Uzbekistan', code: 'UZ' },
]

export default countries
