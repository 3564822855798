import React from 'react'
import { UserContext } from 'components/context'
import { StandardErrorPage } from 'components/DefaultPages'
import ConfirmReactivation from './ConfirmReactivation'
import ReactivationSuccess from './ReactivationSuccess'
import { useLogEvent } from 'components/hooks'

const Index = () => {
  const { membership, apiResource } = React.useContext(UserContext)
  const [success, setSuccess] = React.useState(null)
  const [error, setError] = React.useState(null)
  useLogEvent('view_reactivation')

  const reactivate = () =>
    apiResource({ type: 'DELETE', subRoute: 'cancellations' })
      .then(res => setSuccess(res))
      .catch(err => setError(err))

  const goBack = () => {
    setSuccess(null)
    setError(null)
  }

  if (!membership) return null

  if (error) return <StandardErrorPage error={error} goBack={goBack} />
  if (success) return <ReactivationSuccess />

  return (
    <ConfirmReactivation
      onClick={reactivate}
      membership={membership}
    />
  )
}

export default Index
