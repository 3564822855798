import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { GlobalContext, UserContext } from 'components/context'
import { normalizeStatus } from 'helpers'
import Icon from 'components/icons'

const menuItems = []
menuItems.push(
  ...[
    {
      key: 'payment',
      icon: <Icon type={'card'} />,
      tip: 'navigation.notices.payment',
    },
    {
      key: 'referral',
      icon: <Icon type={'people'} />,
      hiddenForStatuses: ['in_trial', 'presale', 'cancelled', 'stopped'],
    },
  ],
)
if (process.env.REACT_APP_PT_ENABLED) {
  menuItems.push(
    ...[
      {
        key: 'offers',
        icon: <Icon type={'offers'} />,
      },
      {
        key: 'pt',
        icon: <Icon type={'calendar'} />,
      },
    ],
  )
}
menuItems.push(
  ...[
    {
      key: 'membership',
      icon: <Icon type={'person'} />,
    },
    {
      key: 'contact',
      icon: <Icon type={'mail'} />,
    },
  ],
)

const filteredMenuItems = (membership) =>
  menuItems.filter(
    (m) =>
      !m.hiddenForStatuses ||
      !m.hiddenForStatuses.includes(normalizeStatus(membership.status)),
  )

const AttentionIcon = ({ display, title }) => {
  if (!display) return null

  return (
    <>
      <Icon title={title} type={'error'} color={'red'} style={{ height: '14px', 'padding-bottom': '0.7rem' }} />
    </>
  )
}

AttentionIcon.propTypes = {
  display: PropTypes.bool.isRequired,
  title: PropTypes.string,
}

const Navigation = ({ hiddenMobile, location, alerts }) => {
  const { t } = React.useContext(GlobalContext)
  const { membership } = React.useContext(UserContext)

  if (!membership) return null

  return (
    <nav className={`navigation ${hiddenMobile ? 'hidden phone' : ''}`}>
      <ul>
        <NavLink
          className={'hidden phone'}
          to={'/'}
          activeClassName={'active'}
          isActive={() => location.pathname === '/'}
        >
          <li>
            <Icon type={'house'} />
            {t('mypage.title')}
          </li>
        </NavLink>
        {filteredMenuItems(membership).map((e) => (
          <NavLink to={`/${e.key}`} key={e.key} activeClassName={'active'}>
            <li className={`${e.key} ${(alerts && alerts.includes(e.key)) ? 'highlight' : ''}`}>
              {e.icon}
              {t(`navigation.${e.key}`)}
              <AttentionIcon display={alerts && alerts.includes(e.key)} title={t(`navigation.notices.${e.key}`)} />
            </li>
          </NavLink>
        ))}
      </ul>
    </nav>
  )
}

Navigation.propTypes = {
  hiddenMobile: PropTypes.bool,
  location: PropTypes.object.isRequired,
  alerts: PropTypes.array,
}

export default Navigation
