import React from 'react'
import { GlobalContext, UserContext } from 'components/context'
import Icon from 'components/icons'

const monthNameFromMonthIndex = (monthindex, locale) => {
  return new Date(0, monthindex, 0)
    .toLocaleDateString(locale, { month: 'long' })
}

// If we get 404 from the API, then we need to build an
// empty workout to ensure that the UI displays correctly
const noWorkout = () => {
  return {
    total: 0,
    months: [
      {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        count: 0,
        ytd: 0,
      },
    ],
  }
}

const Workouts = () => {
  const { locale, t } = React.useContext(GlobalContext)
  const { get } = React.useContext(UserContext)
  const [workouts, setWorkouts] = React.useState()
  const [month, setMonth] = React.useState(new Date().getMonth() + 1)
  const [year, setYear] = React.useState(new Date().getFullYear())

  const now = new Date()
  const nowYear = now.getFullYear()
  const nowMonth = now.getMonth() + 1

  React.useEffect(() => {
    get('workouts')
      .then(res => setWorkouts(res))
      .catch(() => setWorkouts(noWorkout()))
  }, [get])

  if (!workouts) return null

  const hasPrevious = () => {
    const foundIndex = workouts.months.findIndex(monthlyWorkouts => monthlyWorkouts.year === year && monthlyWorkouts.month === month)
    return foundIndex > -1
  }

  const hasNext = () => {
    return (year < nowYear || (year === nowYear && month < nowMonth))
  }

  const previous = () => {
    if (!hasPrevious()) return

    if (month === 1) {
      setYear(year - 1)
      setMonth(12)
    } else {
      setMonth(month - 1)
    }
  }

  const next = () => {
    if (!hasNext()) return

    if (month === 12) {
      setYear(year + 1)
      setMonth(1)
    } else {
      setMonth(month + 1)
    }
  }

  const nextMonth = () => {
    return monthNameFromMonthIndex(month + 1, locale)
  }

  const prevMonth = () => {
    return monthNameFromMonthIndex(month - 1, locale)
  }

  const workoutCount = () => {
    const monthlyWorkouts = workouts.months.find(m => m.month === month && m.year === year)
    return monthlyWorkouts ? monthlyWorkouts.count : 0
  }

  const ytdCount = () => {
    const monthlyWorkouts = workouts.months.find(m => m.month === month && m.year === year)
    return monthlyWorkouts ? monthlyWorkouts.ytd : 0
  }

  return (
    <div className={'workouts full-width block'}>
      <div className="nav">
        <div className={`previous ${!hasPrevious() ? 'disabled' : ''}`} onClick={previous}>
          <Icon type={'arrow'} direction={'left'} color={hasPrevious() ? '' : 'grey'} />
          { prevMonth() }
        </div>
        <div className="year">
          { year }
        </div>
        <div className={`next ${!hasNext() ? 'disabled' : ''}`} onClick={next}>
          { nextMonth() }
          <Icon type={'arrow'} direction={'right'} color={hasNext() ? '' : 'grey'} />
        </div>
      </div>
      <div className={'number'}>
        { workoutCount() }
      </div>
      <div className={'month'}>
        { t('pages.workouts.count.month', {
          count: workoutCount(),
          month: monthNameFromMonthIndex(month, locale),
        }) }
      </div>
      <div className={'total-this-year'}>
        { t('pages.workouts.count.this_year', {
          count: ytdCount(),
        }) }
      </div>
    </div>
  )
}

export default Workouts
