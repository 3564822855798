import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext } from 'components/context'
import { ActionButton, SecondaryActionButton } from 'components/Buttons'
import { useLogEvent } from 'components/hooks'
import { dateToLocale } from 'helpers'

const Success = ({ endDate }) => {
  useLogEvent('view_cancellation_success')

  const { t, locale } = React.useContext(GlobalContext)

  const formattedEndDate = dateToLocale(endDate, locale)

  return (
    <div>
      <h1>{ t('pages.cancellation.success.title') }</h1>
      <p
        dangerouslySetInnerHTML={{
          __html: t('pages.cancellation.success.info', { date: formattedEndDate }),
        }}
      />
      <Link to={'/membership/reactivate'}>
        <ActionButton>
          { t('pages.cancellation.success.reactivate') }
        </ActionButton>
      </Link>
      <SecondaryActionButton>
        <Link to="/">
          { t('pages.cancellation.success.back') }
        </Link>
      </SecondaryActionButton>
    </div>
  )
}

Success.propTypes = {
  endDate: PropTypes.object,
}

export default Success
