import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/context'
import { Link } from 'react-router-dom'

const Error = ({ error, t }) => {
  if (!error) return null

  const tKey = error.status === 404 ? 'forms.reset-password.notFound' : 'forms.login.error.default'

  return (
    <div className={'error'}>
      { t(tKey) }
    </div>
  )
}

Error.propTypes = {
  error: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const Form = ({ setSuccess }) => {
  const { resetPassword, t } = React.useContext(GlobalContext)
  const [error, setError] = React.useState(null)
  const username = React.createRef()

  const resetURL = `${window.location.origin}/password/update?token=`

  const onSubmit = (e) => {
    e.preventDefault()

    resetPassword(username.current.value, resetURL)
      .then(setSuccess)
      .catch(setError)
  }

  return (
    <div className={'container'}>
      <Error error={error} t={t} />
      <h1>{ t('forms.reset-password.title') }</h1>
      <p>
        { t('forms.reset-password.info') }
      </p>
      <form onSubmit={onSubmit}>
        <label>
          { t('forms.labels.username') }:
          <input
            id={'username'}
            type={'text'}
            name={'username'}
            required
            ref={username}
          />
        </label>
        <Link to={'/'}>
          { t('navigation.back') }
        </Link>
        <button type={'submit'}>
          { t('forms.reset-password.button')}
        </button>
      </form>
    </div>
  )
}

Form.propTypes = {
  setSuccess: PropTypes.func.isRequired,
}

const SubComponent = () => {
  const { t } = React.useContext(GlobalContext)
  const [success, setSuccess] = React.useState(null)

  if (success) {
    return (
      <div className={'container'}>
        <h1>{ t('forms.reset-password.title') }</h1>
        <p>
          { t('forms.reset-password.success') }
        </p>
      </div>
    )
  }

  return (
    <Form setSuccess={setSuccess} />
  )
}

export default SubComponent
