import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { GlobalContext, UserContext } from 'components/context'
import LocationChange from './LocationChange'
import { normalizeStatus } from 'helpers'

const Location = ({ location }) => {
  const { t } = React.useContext(GlobalContext)
  const { logEvent } = React.useContext(UserContext)
  const [showChange, setShowChange] = React.useState(false)

  const toggleLocationChange = () => {
    if (canChange()) {
      setShowChange(!showChange)
    }
  }

  const { get, membership, setMembershipState, post } = React.useContext(UserContext)
  const [clubs, setClubs] = React.useState([])
  const [club, setClub] = useState(location)
  useEffect(() => {
    get('locations')
      .then(res => res.sort((a, b) => a.name.localeCompare(b.name)))
      .then(res => setClubs(res))
      .catch(console.log)
  }, [membership, get])

  const canChange = () => {
    switch (normalizeStatus(membership.status)) {
      case 'presale':
      case 'cancelled':
      case 'cancelled_in_presale':
      case 'cancelled_in_trial':
      case 'stopped': {
        return false
      }
      default: {
        return true
      }
    }
  }

  const handleSubmit = (event, club) => {
    event.preventDefault()
    post('updateLocation', { location_id: club.id })
    membership.location = club
    setClub({ ...club })
    setMembershipState({ ...membership })
    toggleLocationChange()
    logEvent(`change_primary_club_${club.name}`)
      .then(res => {
        console.debug('change club', res)
      })
      .catch(console.error)
  }

  let locationChange
  if (showChange) {
    locationChange = <LocationChange
      clubs={clubs}
      handleSubmit={handleSubmit}
      location={club} />
  }

  return (
    <div>
      <h2>{ t('pages.membership.location.title') }</h2>
      <span className={'location-name'}>
        { location ? location.name : 'Unknown' }
      </span>
      <span
        className="link"
        style={canChange() ? { paddingLeft: '1em', cursor: 'pointer' } : { display: 'none' }}
        onClick={toggleLocationChange}>{ t('pages.membership.location.change') }</span>
      <p>{ t('pages.membership.location.access.all') }</p>
      { locationChange }
    </div>
  )
}

Location.propTypes = {
  location: PropTypes.object,
}

export default Location
