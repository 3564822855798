import React from 'react'
import { GlobalContext, UserContext } from 'components/context'
import { ActionButton } from 'components/Buttons'
import ActivationSuccess from './ActivationSuccess'
import { StandardErrorPage } from 'components/DefaultPages'
import { normalizeStatus } from 'helpers'

const invalidStatus = (status) =>
  ['cancelled', 'stopped'].includes(normalizeStatus(status))

const Index = () => {
  const { membership } = React.useContext(UserContext)
  const { t, T } = React.useContext(GlobalContext)
  const { put } = React.useContext(UserContext)
  const [code, setCode] = React.useState(null)
  const [success, setSuccess] = React.useState(null)
  const [error, setError] = React.useState(null)

  if (!membership) return null

  // // TODO: Display error about status or redirect?
  if (invalidStatus(membership.status)) return null

  const onChange = (e) => {
    setCode(e.target.value)
  }

  const goBack = () => {
    setSuccess(null)
    setError(null)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    put('rfids', { code: code })
      .then(res => setSuccess(res))
      .catch(err => setError(err))
  }

  if (success) return <ActivationSuccess code={code} />
  if (error) return <StandardErrorPage error={error} goBack={goBack} />

  return (
    <div>
      <h1>{ t('pages.activate_chip.title') }</h1>
      <T t={'pages.activate_chip.info'} />
      <form onSubmit={onSubmit} style={{ marginTop: '50px' }}>
        <label onChange={onChange}>
          { t('pages.activate_chip.label') }
          <input type={'text'} />
        </label>
        <ActionButton type={'submit'}>
          { t('pages.activate_chip.button') }
        </ActionButton>
      </form>
    </div>
  )
}

export default Index
