import React from 'react'
import PropTypes from 'prop-types'
import { ActionButton } from 'components/Buttons'

const Option = ({ value, t, onChange, GdprOptIn }) => {
  return (
    <div className={'gdpr-options'}>
      <input
        className={'radio'}
        type={'radio'}
        name={'gdpr_opt_in'}
        value={value}
        defaultChecked={GdprOptIn === value}
        onChange={onChange}
      />
      <span className={'text'}>
        { t(`pages.consents.gdpr_opt_in.${value ? 'yes' : 'no'}`) }
      </span>
    </div>
  )
}

Option.propTypes = {
  GdprOptIn: PropTypes.bool.isRequired,
  value: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

const ConsentsForm = ({ onSubmit, GdprOptIn, handleChange, t }) => {
  return (
    <form onSubmit={onSubmit}>
      <Option
        GdprOptIn={GdprOptIn}
        value={true}
        t={t}
        onChange={handleChange}
      />
      <Option
        GdprOptIn={GdprOptIn}
        value={false}
        t={t}
        onChange={handleChange}
      />
      <br />
      <ActionButton type={'submit'}>
        { t('pages.consents.button') }
      </ActionButton>
    </form>
  )
}

ConsentsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  GdprOptIn: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
}

export default ConsentsForm
