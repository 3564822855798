import React from 'react'
import PropTypes from 'prop-types'

import Arrow from './ArrowIcons'
import CardIcon from './CardIcon'
import ErrorIcon from './ErrorIcon'
import HouseIcon from './HouseIcon'
import OffersIcon from './OffersIcon'
import PeopleIcon from './PeopleIcon'
import PersonIcon from './PersonIcon'
import { MasterCard, Visa } from './CreditCards'
import IconMail from './MailIcon'
import CalendarIcon from './CalendarIcon'

const icons = {
  arrow: Arrow,
  card: CardIcon,
  mail: IconMail,
  mc: MasterCard,
  house: HouseIcon,
  error: ErrorIcon,
  offers: OffersIcon,
  people: PeopleIcon,
  person: PersonIcon,
  visa: Visa,
  calendar: CalendarIcon,
}

const Icon = props => {
  const Component = icons[props.type]

  // Just return null if no icon exists
  if (!Component) {
    return null
  }

  return <Component className={'icon ' + props.type} {...props} />
}

Icon.defaultProps = {
  color: 'link-color',
}

Icon.propTypes = {
  type: PropTypes.oneOf(Object.keys(icons)).isRequired,
  color: PropTypes.string,
}

export default Icon
