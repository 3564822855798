import React from 'react'
import { StandardPage } from 'components/DefaultPages'

const ReactivationSuccess = () =>
  <StandardPage
    headerI18nKey={'pages.reactivate.success.title'}
    type={'success'}
    infoI18nKey={'pages.reactivate.success.info'}
  />

export default ReactivationSuccess
