import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext } from 'components/context'
import { currentActiveRfidFromKeys, statusIsInactive } from 'helpers'

const Info = ({ rfid, t }) => {
  if (!rfid) {
    return (
      <div>
        { t('pages.membership.access_chip.no_chip') }
      </div>
    )
  }

  const vars = (rfid, t) => {
    const statusMap = {
      active: 'activated',
      inactive: 'deactivated',
      suspended: 'suspended',
    }

    const statusTxt = t(`pages.membership.access_chip.${statusMap[rfid.status]}`)

    return {
      code: `<strong>${rfid.code}</strong>`,
      status: `<span class="${rfid.status}">${statusTxt}</span>`,
    }
  }

  const txt = t(
    'pages.membership.access_chip.rfid',
    vars(rfid, t),
  )

  return (
    <div
      className={'chip'}
      dangerouslySetInnerHTML={{ __html: txt }}
    />
  )
}

Info.propTypes = {
  rfid: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const ActionLink = ({ rfid, match, t }) => {
  const path = rfid
    ? 'lost_chip'
    : 'activate_chip'

  const txt = rfid
    ? 'pages.membership.access_chip.lost'
    : 'pages.membership.access_chip.activate'

  return (
    <p>
      <Link to={`${match.path}/${path}`} data-cy={path}>
        { t(txt) }
      </Link>
    </p>
  )
}

ActionLink.propTypes = {
  rfid: PropTypes.object,
  match: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

// TODO: Should consider sanitizing first
//  when using dangerouslySetInnerHTML
//  https://www.npmjs.com/package/sanitize-html
const AccessChip = ({ match, membership }) => {
  const { t } = React.useContext(GlobalContext)

  if (!membership) return null
  if (statusIsInactive(membership.status)) return null

  const rfid = currentActiveRfidFromKeys(membership.keys)

  return (
    <div id={'access-chip'}>
      <h2>{ t('pages.membership.access_chip.title') }</h2>
      <Info rfid={rfid} t={t} />
      <ActionLink rfid={rfid} t={t} match={match} />
      <hr />
    </div>
  )
}

AccessChip.propTypes = {
  match: PropTypes.object.isRequired,
  membership: PropTypes.object,
}

export default AccessChip
