import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/context'
import { ActionButton } from 'components/Buttons'

const LocationChange = ({ location, clubs, handleSubmit }) => {
  const { t } = React.useContext(GlobalContext)
  const [club, setClub] = useState(location)
  const today = new Date().toISOString()
  const openClubs = clubs.filter(e => {
    return e.opening_date <= today && (e.closing_date === null || e.closing_date > today)
  })
  const initial = openClubs.findIndex(c => c.id === location.id)

  const handleChange = (event) => {
    const idx = event.target.value
    setClub(openClubs[idx])
  }

  return (
    <div>
      <h2>{ t('pages.membership.location.update') }</h2>
      <form onSubmit={(event) => handleSubmit(event, club)}>
        <select onChange={handleChange} defaultValue={initial}>
          { openClubs.map((c, index) => <option key={index} value={index}>{ c.name }</option>) }
        </select>
        <ActionButton type='submit'>{ t('pages.membership.location.update') }</ActionButton>
      </form>
    </div>
  )
}

LocationChange.propTypes = {
  location: PropTypes.object,
  handleSubmit: PropTypes.func,
  clubs: PropTypes.array,
}

export default LocationChange
