import React from 'react'
import PropTypes from 'prop-types'

const ActionButton = ({ className, children, ...rest }) => {
  return (
    <div className={'flex-center-wrapper'}>
      <button type={'button'} className={className} {...rest}>
        { children }
      </button>
    </div>
  )
}

ActionButton.propTypes = {
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  type: PropTypes.string,
}

ActionButton.defaultProps = {
  className: 'action-button',
}

const SecondaryActionButton = (props) =>
  <ActionButton className={'action-button secondary'} {...props} />

// TODO: Find better name
const SmallerTextActionButton = (props) =>
  <ActionButton className={'action-button long'} {...props} />

// TODO: Find better name
const SmallerTextSecondaryActionButton = (props) =>
  <ActionButton className={'action-button secondary long'} {...props} />

export {
  ActionButton,
  SecondaryActionButton,
  SmallerTextSecondaryActionButton,
  SmallerTextActionButton,
}
