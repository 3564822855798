import React from 'react'
import { GlobalContext } from 'components/context'

const PTBooking = () => {
  const { locale } = React.useContext(GlobalContext)

  window.sessionStorage.setItem(
    'pts-token',
    window.localStorage.getItem('token'))

  return (
    <div id={'pt-booking'}>
      <pt-member-booking locale={locale}></pt-member-booking>
    </div>
  )
}

export default PTBooking
