import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext, UserContext } from 'components/context'

const HighlightBox = ({ t, type, logoPath }) =>
  <div className={'highlighted full-width'}>
    <strong>{ t(`highlights.${type}.title`) }</strong>
    <div className={'phone'}>
      <img
        src={logoPath}
        alt='logo'
      />
      <div className={'line'} />
    </div>
    <div>
      { t(`highlights.${type}.content`) }
    </div>
  </div>

HighlightBox.propTypes = {
  logoPath: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
}

const Highlight = ({ type, statuses = [], external_url: externalURL, internal_url: internalURL }) => {
  const { t, theme } = React.useContext(GlobalContext)
  const { membership } = React.useContext(UserContext)

  if (!membership) return null
  if (statuses.length > 0 && !statuses.includes(membership.status)) return null

  const logoPath = `/themes/${theme}/logo.png`

  const keyPresent = (field) => {
    const tKey = `highlights.${type}.${field}`
    const translation = t(tKey)

    return tKey !== translation && translation.length > 0
  }

  if (!keyPresent('title') || !keyPresent('content')) {
    return null
  }

  if (keyPresent('statuses')) {
    const tmp = t(`highlights.${type}.statuses`, { returnObjects: true })

    if (!tmp.includes(membership.status)) {
      return null
    }
  }

  if (internalURL) {
    return (
      <Link to={internalURL} >
        <HighlightBox t={t} type={type} logoPath={logoPath} />
      </Link>
    )
  }

  if (externalURL) {
    return (
      <a href={externalURL} target={'_blank'} rel={'noopener noreferrer'}>
        <HighlightBox t={t} type={type} logoPath={logoPath} />
      </a>
    )
  }

  return (
    <HighlightBox t={t} type={type} logoPath={logoPath} />
  )
}

Highlight.propTypes = {
  external_url: PropTypes.string,
  internal_url: PropTypes.string,
  type: PropTypes.string.isRequired,
  statuses: PropTypes.array,
}

const Highlights = () => {
  const { t } = React.useContext(GlobalContext)
  const highlights = t('highlights', { returnObjects: true })

  return (
    <div className={'highlight-wrapper'}>
      {
        Object.keys(highlights).map(key =>
          <Highlight key={key} type={key} { ...highlights[key] }/>,
        )
      }
    </div>
  )
}

export default Highlights
