import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/context'
import { options, result, routes } from '../api'
import fetch from 'fetch'

const UserContext = React.createContext()

const api = (type, token, route, body = null) => {
  const opts = options({
    method: type,
    token: token,
    body: body,
  })

  return fetch(route, opts)
    .then(res => result(res))
}

const UserState = (props) => {
  const { token, clearToken } = React.useContext(GlobalContext)
  const [membership, setMembershipState] = useState()

  const refreshMember = () =>
    apiResource({ type: 'GET', subRoute: 'membership' }).then(res => setMembershipState(res))

  const apiResource = ({ type, subRoute, body, id }) => {
    // Append id to the route if present
    let route = id ? `${routes[subRoute]}/${id}` : routes[subRoute]

    if (!route) {
      route = subRoute
    }

    return api(type, token, route, body)
      .then(async (res) => {
        // Refresh membership for all actions that might change the membership
        if (type !== 'GET') {
          refreshMember()
        }
        return res
      })
      .catch(err => {
        return err.status === 401 ? clearToken() : Promise.reject(err)
      })
  }

  const dibsUrl = (locale, location) => {
    const apiURL = process.env.REACT_APP_API

    const cancel = `${apiURL}/dibs/http://${window.location.host}/payment`
    const success = `${apiURL}/dibs/http://${window.location.host}/payment/payment-method-updated`

    let route = `/v1/membership/dibs_url?cancel=${cancel}&success=${success}&locale=${locale || localStorage.getItem('locale')}&location=${location}`

    if (process.env.REACT_APP_DIBS_TEST === 'true') {
      route = route + '&test=true'
    }

    // NOTE: We "proxy" cancelurl and accepturl through our own API
    //  because DIBS will use POST for cancelurl and GET/POST for accepturl.
    //  So we have added our own open API routes in the mypage-api
    //  that redirects after requests from DIBS.
    //  This way we don't have to implement express routes in this application
    return api('GET', token, route)
  }

  const verifoneUrl = () => {
    const route = '/v1/membership/verifone_url'
    return api('GET', token, route)
  }

  const changeCreditCardUrl = (locale, location, existingProvider) => {
    let env = 'notProduction'
    if (/me.*/.test(window.location.origin)) {
      env = 'production'
    }

    return env === 'notProduction' || existingProvider === 'verifone' ? verifoneUrl() : dibsUrl(locale, location)
  }

  const logEvent = (eventObject) => {
    // Legacy codes supplies event name only - if so, wrap as object
    if (typeof eventObject === 'string') {
      eventObject = { action: eventObject }
    }

    // Use Fetch directly as we cannot reload membership on each event log POST
    return fetch('/v1/membership/event', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: token,
      },
      body: JSON.stringify(eventObject),
    })
      .then(res => result(res))
  }

  const ctx = {
    membership,
    setMembershipState,
    get: (route, id = null) => apiResource({ type: 'GET', subRoute: route, id: id }),
    post: (route, body = null) => apiResource({ type: 'POST', subRoute: route, body: body }),
    put: (route, body = null) => apiResource({ type: 'PUT', subRoute: route, body: body }),
    patch: (route, body = null) => apiResource({ type: 'PATCH', subRoute: route, body: body }),
    apiDelete: (route, id = null, body = null) => apiResource({ type: 'DELETE', subRoute: route, id: id, body: body }),
    apiResource,
    changeCreditCardUrl,
    verifoneUrl,
    logEvent,
  }

  return (
    <UserContext.Provider value={ ctx }>
      { props.children }
    </UserContext.Provider>
  )
}

UserState.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export {
  UserState,
  UserContext,
}
