import React from 'react'
import PropTypes from 'prop-types'

const CardIcon = ({ color }) =>
  <svg width='1em' height='1em' viewBox='0 0 21 14'>
    <defs>
      <path
        d='M21,2.625 L21,2.1875 C21,0.98175 20.01825,0 18.8125,0 L2.1875,0 C0.980875,0 0,0.98175 0,2.1875 L0,2.625 L21,2.625 Z M0,6.125 L21,6.125 L21,11.8125 C21,13.01825 20.01825,14 18.8125,14 L2.1875,14 C0.980875,14 0,13.01825 0,11.8125 L0,6.125 Z M0,5.25 L0,3.5 L21,3.5 L21,5.25 L0,5.25 Z'
        id='CardPath1' />
    </defs>
    <g id='CardSymbol' fill='none' fillRule='evenodd'>
      <g id='Icon/payment' transform='translate(-1 -5)'>
        <g transform='translate(1 5)' id='↳-🎨Color'>
          <mask id='card-mask-2' fill='#fff'>
            <use xlinkHref='#CardPath1' />
          </mask>
          <use id='Mask' fill='#000' xlinkHref='#CardPath1' />
          <g id='Mixin/Fill/Black' mask='url(#card-mask-2)' className={color}>
            <rect width='26' height='23' transform='translate(-2 -5)' id='CardRectangle'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

CardIcon.propTypes = {
  color: PropTypes.string.isRequired,
}

export default CardIcon
