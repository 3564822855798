import React from 'react'
import PropTypes from 'prop-types'

const CalendarIcon = ({ color }) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="2"
      y="3"
      width="20"
      height="17"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.6434 17.7864C20.6434 18.4354 20.1146 18.965 19.4648 18.965H4.53565C3.88584 18.965 3.35703 18.4354 3.35703 17.7864V9.53605H20.6434V17.7864ZM19.4648 4.82159C19.2479 4.82159 19.0719 4.9976 19.0719 5.21446V6.00021C19.0719 7.08217 18.1903 7.96456 17.1075 7.96456C16.0248 7.96456 15.1432 7.08217 15.1432 6.00021V5.21446C15.1432 4.9976 14.9672 4.82159 14.7503 4.82159H9.2501C9.03324 4.82159 8.85723 4.9976 8.85723 5.21446V6.00021C8.85723 7.08217 7.97563 7.96456 6.89287 7.96456C5.81012 7.96456 4.92852 7.08217 4.92852 6.00021V5.21446C4.92852 4.9976 4.75251 4.82159 4.53565 4.82159C3.45289 4.82159 2.57129 5.7032 2.57129 6.78595V17.7863C2.57129 18.8691 3.45289 19.7507 4.53565 19.7507H19.4648C20.5475 19.7507 21.4291 18.8691 21.4291 17.7863V6.78595C21.4291 5.7032 20.5475 4.82159 19.4648 4.82159ZM6.89287 7.17882C7.54268 7.17882 8.07149 6.65002 8.07149 6.00021V4.42872C8.07149 3.77891 7.54268 3.25011 6.89287 3.25011C6.24307 3.25011 5.71426 3.77891 5.71426 4.42872V6.00021C5.71426 6.65002 6.24307 7.17882 6.89287 7.17882ZM17.1075 7.17882C17.7573 7.17882 18.2861 6.65002 18.2861 6.00021V4.42872C18.2861 3.77891 17.7573 3.25011 17.1075 3.25011C16.4577 3.25011 15.9289 3.77891 15.9289 4.42872V6.00021C15.9289 6.65002 16.4577 7.17882 17.1075 7.17882Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <rect width="24" height="24" fill="#C00080" />
    </g>
  </svg>
)
CalendarIcon.propTypes = {
  color: PropTypes.string.isRequired,
}

export default CalendarIcon
