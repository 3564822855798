import React from 'react'
import { Link } from 'react-router-dom'
import { ActionButton } from 'components/Buttons'
import { GlobalContext } from 'components/context'
import { useLogEvent } from 'components/hooks'
import List from './components/List'
import CMS from './components/CMS'

const Tips = () => {
  useLogEvent('view_cancellation_tips')

  const { t, locale } = React.useContext(GlobalContext)
  const content = CMS[locale] || CMS.default

  return (
    <div>
      <h1>{ t('pages.cancellation.tips.title') }</h1>
      <p>{ t('pages.cancellation.tips.body.top') }</p>
      <List items={content} />
      <p dangerouslySetInnerHTML={{ __html: t('pages.cancellation.tips.body.bottom') }} />
      <Link to={'/membership/cancellation/missing-out-on'}>
        <ActionButton>
          { t('pages.cancellation.tips.next') }
        </ActionButton>
      </Link>
    </div>
  )
}

export default Tips
