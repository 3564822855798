import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext, UserContext } from 'components/context'
import { StandardErrorPage } from 'components/DefaultPages'
import { ConsentsForm, ConsentSuccess } from './components'

const Consents = ({ membership }) => {
  const { t } = React.useContext(GlobalContext)
  const { put } = React.useContext(UserContext)
  const [success, setSuccess] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [consent, setConsent] = React.useState(null)

  const { gdpr_opt_in: GdprOptIn } = membership

  const goBack = () => {
    setSuccess(null)
    setError(null)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    // defaulting to the value stored for the member if no changes made
    put('gdpr', { gdpr_opt_in: consent !== null ? consent : GdprOptIn })
      .then(res => setSuccess(res))
      .catch(err => setError(err))
  }

  const handleChange = (event) => {
    const { value } = event.target

    // Forcing to boolean instead of true/false string
    setConsent(JSON.parse(value))
  }

  if (success) return <ConsentSuccess />
  if (error) return <StandardErrorPage error={error} goBack={goBack} />

  return (
    <div id={'gdpr_opt_in'}>
      <h2>{ t('pages.consents.title') }</h2>
      <ConsentsForm
        onSubmit={onSubmit}
        GdprOptIn={GdprOptIn}
        handleChange={handleChange}
        t={t}
      />
    </div>
  )
}

Consents.propTypes = {
  membership: PropTypes.object,
}

export default Consents
