import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext } from 'components/context'

const Consent = ({ membership, match }) => {
  const { t } = React.useContext(GlobalContext)

  return (
    <div className={'consents'}>
      <h2>{ t('pages.membership.consents.title') }</h2>
      { t(`pages.membership.consents.${membership.gdpr_opt_in ? 'consented' : 'not_consented'}`) }
      <p>
        <Link to={`${match.path}/consents`}>
          { t('pages.membership.consents.link') }
        </Link>
      </p>
    </div>
  )
}

Consent.propTypes = {
  match: PropTypes.object.isRequired,
  membership: PropTypes.object.isRequired,
}

export default Consent
