/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import { Link, Redirect, Route } from 'react-router-dom'
import { GlobalContext, UserContext } from 'components/context'
import { AccessChip, Concent, Location, Profile, Status, Terms } from './components'
import FreezePage from './pages/Freeze'
import CancelFreeze from './pages/FreezeCancel'
import Cancellation from './pages/Cancellation'
import Reactivate from './pages/Reactivate'
import UpdateProfile from './pages/Profile'
import ConsentsWrapper from './pages/Consents'
import { ActivateRFID, LostRFID } from './pages/Access'
import { useLogEvent } from 'components/hooks'
import isShutdown from './components/isShutdown'

const Cancel = ({ match, status, t }) => {
  if (isShutdown()) return null; // use to remove both cancel _and_ freeze button

  // DE hack to hide cancel button - currently disabled
  const { membership } = React.useContext(UserContext)
  if (membership.operator.id === 'c04bc7c3-903f-4804-a952-9abc0016b824-hack-disabled') {
    return null
  }

  // Simplified check for when to display the cancel button
  // TODO: Should get info from cancellation policy
  const allowedStatus = ['presale', 'active', 'in_trial','in_freeze']
  if (!allowedStatus.includes(status)) {
    return null
  }

  return (
    <div className={'cancel'}>
      <Link to={`${match.path}/cancellation`}>
        X { t('pages.membership.cancellation_button') }
      </Link>
    </div>
  )
}

Cancel.propTypes = {
  match: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const Membership = ({ match }) => {
  const { membership } = React.useContext(UserContext)
  const { t } = React.useContext(GlobalContext)
  useLogEvent('view_membership', membership)

  if (!membership) return null

  return (
    <div className={'membership'} style={{ marginBottom: '40px' }}>
      <h1>{ t('pages.membership.title') }</h1>
      <Status membership={membership} t={t} />
      <Location location={membership.location} />
      <hr />
      <AccessChip
        membership={membership}
        t={t}
        match={match}
      />
      <Profile
        profile={membership.profile}
        match={match}
        t={t}
      />
      <hr />
      <Terms product={membership.product} />
      <hr />
      <Concent
        membership={membership}
        match={match}
      />
      <hr />
      <Cancel
        match={match}
        status={membership.status}
        t={t}
      />
    </div>
  )
}

Membership.propTypes = {
  match: PropTypes.object.isRequired,
}

const Dummy = ({ location }) => {
  const { t } = React.useContext(GlobalContext)
  const page = location.pathname.split('/').slice(-1)[0]

  // Ignore pages that has been implemented
  const implemented = [
    'freeze',
    'cancel_freeze',
    'cancellation',
    'reactivate',
    'activate_chip',
    'lost_chip',
    'consents',
    'profile',
  ]
  if (implemented.includes(page)) return null

  return (
    <h1>{ t(`pages.${page}.title`) }</h1>
  )
}

Dummy.propTypes = {
  location: PropTypes.object.isRequired,
}

const RedirectComponent = () =>
  <Redirect to={'/membership'} />

const RouteForStatus = ({ component, statuses, membership, ...rest }) => {
  const Component = statuses.includes(membership.status) ? component : RedirectComponent

  return (
    <Route {...rest}>
      <Component membership={membership} />
    </Route>
  )
}

RouteForStatus.propTypes = {
  membership: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired,
  component: PropTypes.elementType.isRequired,
}

const MembershipWrapper = ({ membership }) => {
  if (!membership) return null

  return (
    <>
      <Route path={'/membership'} exact component={Membership} />
      <Route path={'/membership/freeze'} component={FreezePage} />
      <Route path={'/membership/cancellation'} component={Cancellation} />
      <RouteForStatus
        path={'/membership/cancel_freeze'}
        statuses={['active', 'in_freeze']}
        component={CancelFreeze}
        membership={membership}
      />
      <RouteForStatus
        path={'/membership/reactivate'}
        statuses={['in_cancellation']}
        component={Reactivate}
        membership={membership}
      />
      <RouteForStatus
        path={'/membership/activate_chip'}
        statuses={['active', 'presale', 'in_trial', 'in_freeze', 'in_cancellation']}
        component={ActivateRFID}
        membership={membership}
      />
      <RouteForStatus
        path={'/membership/lost_chip'}
        statuses={['active', 'presale', 'in_trial', 'in_freeze', 'in_cancellation']}
        component={LostRFID}
        membership={membership}
      />
      <Route path={'/membership/consents'} component={ConsentsWrapper} />
      <Route path={'/membership/profile'} component={UpdateProfile} />
      <Route path={'/membership/:page'} exact component={Dummy} />
    </>
  )
}

MembershipWrapper.propTypes = {
  membership: PropTypes.object,
}

export default MembershipWrapper
