import React from 'react'
import PropTypes from 'prop-types'

const PersonIcon = ({ color, ...rest }) =>
  <svg width='1em' height='1em' viewBox='0 0 16 16' {...rest}>
    <defs>
      <path
        d='M8,8 C5.795,8 4,6.205 4,4 C4,1.795 5.794,0 8,0 C10.206,0 12,1.795 12,4 C12,6.205 10.206,8 8,8 Z M8,9 C12.711,9 16,11.467 16,15 L16,16 L0,16 L0,15 C0,11.467 3.29,9 8,9 Z'
        id='PersonPath1' />
    </defs>
    <g id='Symbols' fill='none' fillRule='evenodd'>
      <g id='Icon/User' transform='translate(-4 -4)'>
        <g id='Group' transform='translate(4 4)'>
          <mask id='person-mask-2' fill='#fff'>
            <use xlinkHref='#PersonPath1' />
          </mask>
          <g id='Mixin/Fill/Black' mask='url(#person-mask-2)' className={color}>
            <rect width='24' height='24' transform='translate(-4 -4)' id='Rectangle'
            />
          </g>
        </g>
      </g>
    </g>
  </svg>

PersonIcon.propTypes = {
  color: PropTypes.string.isRequired,
}

export default PersonIcon
