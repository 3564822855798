import React from 'react'
import PropTypes from 'prop-types'
import { StandardErrorPage } from 'components/DefaultPages'

const ErrorPage = ({ error, goBack }) =>
  <StandardErrorPage
    error={error}
    goBack={goBack}
  />

ErrorPage.propTypes = {
  error: PropTypes.object.isRequired,
  goBack: PropTypes.func.isRequired,
}

export default ErrorPage
