import React from 'react'
import ForgottenPasswordForm from './Reset'
import UpdatePasswordForm from './Update'

const ForgottenPassword = () => {
  return (
    <ForgottenPasswordForm/>
  )
}

const UpdatePassword = (props) => {
  return (
    <UpdatePasswordForm {...props} />
  )
}

export {
  ForgottenPassword,
  UpdatePassword,
}
