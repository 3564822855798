import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import CancelOrFreeze from './CancelOrFreeze'
import ConfirmCancellation from './ConfirmCancellation'
import Tips from './Tips'
import MissingOutOn from './MissingOutOn'
import { UserContext } from 'components/context'
import { StandardErrorPage } from 'components/DefaultPages'
import ValidationError from './ValidationError'

// API returns a cancellation policy which may contain
// validation errors
const Validate = () => {
  const [policy, setPolicy] = React.useState()
  const [error, setError] = React.useState(null)
  const { get } = React.useContext(UserContext)
  const goBack = () => setError(null)

  React.useEffect(() => {
    get('cancellation_policy')
      .then(res => {
        console.log('cancel', res)
        setPolicy(res)
      })
      .catch(err => {
        setError(err)
      })
  }, [get])

  if (error) return <StandardErrorPage error={error} goBack={goBack} />
  if (!policy) return null
  if (!policy.membership) return null

  if (policy.membership.errors) {
    // Only consider 1st validation error. We most likely got 1 and
    // it yields a more direct error messgage
    return <ValidationError error={policy.membership.errors[0]} />
  }

  return <Redirect to="/membership/cancellation/freeze" />
}

const CancellationWrapper = () => {
  return (
    <>
      <Route path={'/membership/cancellation'} exact component={Validate} />
      <Route path={'/membership/cancellation/freeze'} exact component={CancelOrFreeze} />
      <Route path={'/membership/cancellation/tips'} component={Tips} />
      <Route path={'/membership/cancellation/missing-out-on'} component={MissingOutOn} />
      <Route path={'/membership/cancellation/confirm'} component={ConfirmCancellation} />
    </>
  )
}

export default CancellationWrapper
