import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/context'
import { Link } from 'react-router-dom'

const Error = ({ error, t }) => {
  if (!error) return null

  let tKey = 'forms.login.error.default'

  if (error.status === 404 || error.status === 422) {
    tKey = 'forms.update-password.notFound'
  }

  return (
    <div className={'error'}>
      { t(tKey) }
    </div>
  )
}

Error.propTypes = {
  error: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const Form = ({ setSuccess, token }) => {
  const { updatePassword, t } = React.useContext(GlobalContext)
  const [error, setError] = React.useState(null)
  const password = React.createRef()

  const onSubmit = (e) => {
    e.preventDefault()

    updatePassword(token, password.current.value)
      .then(setSuccess)
      .catch(setError)
  }

  return (
    <div className={'container'}>
      <Error error={error} t={t} />
      <h1>{ t('forms.update-password.title') }</h1>
      <p>
        { t('forms.update-password.info') }
      </p>
      <form onSubmit={onSubmit}>
        <label>
          { t('forms.labels.password') }:
          <input
            id={'password'}
            type={'password'}
            name={'password'}
            ref={password}
            minLength={3}
            required
          />
        </label>
        <Link to={'/'}>
          { t('navigation.back') }
        </Link>
        <button type={'submit'}>
          { t('forms.update-password.button') }
        </button>
      </form>
    </div>
  )
}

Form.propTypes = {
  setSuccess: PropTypes.func.isRequired,
  token: PropTypes.string,
}

const SubComponent = ({ location }) => {
  const { t } = React.useContext(GlobalContext)
  const [success, setSuccess] = React.useState(null)

  const query = new URLSearchParams(location.search)
  const token = query.get('token')

  if (success) {
    return (
      <div className={'container'}>
        <h1>{ t('forms.update-password.title') }</h1>
        <p>
          { t('forms.update-password.success') }
        </p>
        <Link to={'/'}>{ t('forms.login.button') }</Link>
      </div>
    )
  }

  return (
    <Form setSuccess={setSuccess} token={token} />
  )
}

SubComponent.propTypes = {
  location: PropTypes.object,
}

export default SubComponent
