export default [
  {
    id: 'abc',
    invoice_number: 1,
    status: 'declined',
    invoice_date: '2019-06-01',
    amount: 10000,
    currency: 'NOK',
    period: {
      from: '2019-06-01',
      to: '2019-06-30',
    },
  },
  {
    id: 'abc',
    invoice_number: 2,
    status: 'charged',
    invoice_date: '2019-05-01',
    amount: 10000,
    currency: 'NOK',
    period: {
      from: '2019-05-01',
      to: '2019-05-31',
    },
  },
]
