// Private
const fixDateForAllBrowsers = dateString => {
  if (typeof dateString !== 'string') {
    return dateString
  }
  return dateString.replace(/-/g, '/')
}

// Returns true for statuses
// cancelled, cancelled_in_trial and cancelled_in_presale
const cancelled = (status) =>
  status.split('_')[0] === 'cancelled'

// Public
const centToDecimal = int => (int / 100).toFixed(2)

const dateToLocale = (date, locale) => {
  if (!date) return 'N/A'
  const options = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }
  return new Date(fixDateForAllBrowsers(date))
    .toLocaleDateString(locale, options)
}

const amountToCurrency = (amount, locale, currency) =>
  new Intl.NumberFormat(
    locale,
    {
      style: 'currency',
      currency: currency,
    },
  ).format(centToDecimal(amount))

const statusIsInactive = (status) =>
  ['cancelled', 'stopped'].includes(normalizeStatus(status))

const normalizeStatus = (status) => {
  if (cancelled(status)) {
    return 'cancelled'
  }

  return status
}

// A helper method that can be used to resolve a object path from a string
// E.g. resolvePath(obj, 'something.other_thing') will
// return the value of obj.something.other_thing
const objByStringKey = (obj, path, seperator = '.') =>
  path.split(seperator).reduce((prev, curr) => prev && prev[curr], obj)

// A helper method that finds the current active RFID from a list of membership keys.
const currentActiveRfidFromKeys = (keys) => {
  if (!keys) return

  const activeKeys = keys.filter(k => k.type === 'rfid' && !k.valid_to)

  if (activeKeys.length < 1) return
  if (activeKeys.length === 1) return activeKeys[0]

  // There might be more than on valid RFID, so
  // we find the one that was activated last
  return activeKeys.reduce((a, b) => a.valid_from > b.valid_from ? a : b)
}

export {
  amountToCurrency,
  centToDecimal,
  currentActiveRfidFromKeys,
  dateToLocale,
  normalizeStatus,
  statusIsInactive,
  objByStringKey,
}
