import React from 'react'
import PropTypes from 'prop-types'
import i18n from '../i18n'
import { GlobalContext } from 'components/context'

const LocaleButton = ({ onClick, language, selected }) => {
  const style = {
    marginRight: '10px',
    fontWeight: selected ? 'bold' : '',
  }

  return (
    <button
      className={'link-button'}
      onClick={onClick}
      style={style}
    >
      { language }
    </button>
  )
}

LocaleButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
}

const LocaleSwitcher = () => {
  const { locale, setLocale, locales } = React.useContext(GlobalContext)

  React.useEffect(() => {
    i18n.changeLanguage(locale)
  }, [locale])

  return (
    <span style={{ float: 'right' }}>
      {
        locales.map((l) =>
          <LocaleButton
            key={l}
            language={l.substring(0, 2)}
            onClick={() => setLocale(l)}
            selected={locale === l}
          />,
        )
      }
    </span>
  )
}

export default LocaleSwitcher
