import React from 'react'
import { GlobalContext, UserContext } from 'components/context'
import { Link } from 'react-router-dom'

const lastPin = (keys) => {
  if (!keys) return

  const pins = keys.filter(k => k.type === 'pin')
  if (pins.length < 1) return
  if (pins.length === 1) return pins[0]

  // There might be more than one PIN, so
  // we find the one that was activated last
  return pins.reduce((a, b) => a.valid_from > b.valid_from ? a : b)
}

const PinInfo = () => {
  const { t } = React.useContext(GlobalContext)
  const { membership } = React.useContext(UserContext)

  if (!membership) return null

  const pin = lastPin(membership.keys)
  // eslint-disable-next-line no-unneeded-ternary
  const pinActive = pin && pin.status === 'inactive' ? false : true

  return (
    <div className={'index-info full-width'}>
      <h2>{ t('pages.pin.title') }</h2>
      <div className={ pinActive ? 'code' : 'code-inactive'}>{pin ? pin.code : null}</div>
      <div className={ pinActive ? 'invisible' : 'expiry-label'}>Expired</div>
      <div className={'access-info'}>
        <h3>{ t('pages.pin.list.title') }</h3>
        <ol>
          <li>{ t('pages.pin.list.use_code') }</li>
          <li>
            { t('pages.pin.list.pick_up_access_chip') }
          </li>
          <li>
            <Link to={'/membership/activate_chip'}>
              { t('pages.pin.list.activate_access_chip') }
            </Link>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default PinInfo
