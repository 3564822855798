import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import LocaleSwitcher from 'components/context/global/components/LocaleSwitcher'

const logoPath = (theme) => `/themes/${theme}/logo.png`

const TopBar = ({ clearToken, t, theme }) =>
  <div id='top-bar'>
    <div>
      <Link to={'/'}>
        <img
          src={logoPath(theme)}
          style={{ width: '48px', marginRight: '10px' }}
          alt='main-logo'
        />
      </Link>
      <span>{ t('mypage.title') }</span>
      <button
        style={{ float: 'right', minWidth: '70px' }}
        className='link-button sign-out'
        onClick={() => clearToken()}
      >
        { t('navigation.sign_out') }
      </button>
      <LocaleSwitcher />
    </div>
  </div>

TopBar.propTypes = {
  clearToken: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
}

export default TopBar
