const currYear = new Date().getFullYear()
const currMonth = new Date().getMonth() + 1
let year = currYear - 2
let month = (currMonth + 6) % 12

function getRandom (min, max) {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

const months = []
let total = 0
let ytd = 0
while (year < currYear || (year === currYear && month <= currMonth)) {
  const count = getRandom(0, 28)
  ytd += count
  total += count
  months.push({
    year,
    month,
    count,
    ytd,
  })
  month += 1
  if (month === 13) {
    month = 1
    year += 1
    ytd = 0
  }
}

export default {
  total,
  months,
}
