/* eslint-disable */

import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { normalizeStatus } from 'helpers'
import isShutdown from './isShutdown'

// TBD: Should we calculate some more metadata in the api?
// E.g adding a "activates_on" key for membership in trial, freeze and presale?
const MembershipInfo = ({ membership, t }) => {
  let date
  let translationKey

  switch (normalizeStatus(membership.status)) {
    case 'active': {
      date = membership.begin_date
      translationKey = 'since'
      break
    }
    case 'in_cancellation': {
      date = membership.end_date
      translationKey = 'ends_at'
      break
    }
    case 'in_freeze': {
      date = membership.activates_on
      translationKey = 'activates'
      break
    }
    case 'cancelled': {
      date = membership.end_date
      translationKey = 'ended'
      break
    }
    case 'in_trial':
    case 'presale': {
      date = membership.begin_date
      translationKey = 'activates'
      break
    }
    default: {
      return null
    }
  }

  const Freeze = () => {
    if (!membership.scheduled_freeze || membership.status !== 'active') return null

    const tArgs = {
      begin: membership.scheduled_freeze.begin_date,
      end: membership.scheduled_freeze.end_date,
    }

    return (
      <span>
        <br />
        { t('pages.membership.status.scheduled_freeze', tArgs) }
      </span>
    )
  }

  return (
    <div className={'membership-info'}>
      { t(`pages.membership.status.${translationKey}`, { date: date }) }
      <Freeze />
    </div>
  )
}

MembershipInfo.propTypes = {
  membership: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

const ActionButton = ({ scheduledFreeze, status, t }) => {

  if (isShutdown()) return null;

  const actionMap = {
    active: {
      link: 'freeze',
      button: 'freeze',
    },
    in_cancellation: {
      link: 'reactivate',
      button: 'reactivate',
    },
    in_freeze: {
      link: 'freeze',
      button: 'update_freeze',
    },
    // AT corona hack 18. May 2021: hide cancel
    // in_trial: {
    //   link: 'cancellation',
    //   button: 'cancel',
    // },
    // presale: {
    //   link: 'cancellation',
    //   button: 'cancel',
    // },
    scheduledFreeze: {
      link: 'freeze',
      button: 'update_scheduled_freeze',
    },
  }

  const action = actionMap[status === 'active' && scheduledFreeze ? 'scheduledFreeze' : status]

  if (!action) return null

  return (
    <Link to={`/membership/${action.link}`}>
      <button>
        { t(`status.actions.${action.button}`) }
      </button>
    </Link>
  )
}

ActionButton.propTypes = {
  scheduledFreeze: PropTypes.object,
  status: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const name = (profile) => {
  if (!profile) return null
  return `${profile.first_name} ${profile.last_name}`
}

const Status = ({ membership, t }) =>
  <div className={`status ${membership.status}`}>
    <div className={'name'}>
      { name(membership.profile) }
    </div>
    <div className={'membership-status'}>
      { t(`status.${membership.status}`) }
    </div>
    <MembershipInfo
      membership={membership}
      t={t}
    />
    <ActionButton
      status={membership.status}
      scheduledFreeze={membership.scheduled_freeze}
      t={t}
    />
  </div>

Status.propTypes = {
  membership: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
}

export default Status
