export default [
  {
    id: '2ce04e23-361e-4e8e-b73b-55ac3c27b558',
    name: 'EVO Haugesund',
    opening_date: '2013-08-15',
    closing_date: null,
  },
  {
    id: 'evo-bryn',
    name: 'EVO Bryn',
    opening_date: '2018-08-15',
    closing_date: null,
  },
  {
    id: 'evo-sentrum',
    name: 'EVO Sentrum',
    opening_date: '2016-08-15',
    closing_date: null,
  },
  {
    id: 'evo-futurama',
    name: 'EVO Futurama',
    opening_date: '3000-01-01',
    closing_date: null,
  },
]
