import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/context'
import { dateToLocale } from 'helpers'
import { StandardPage } from 'components/DefaultPages'

const FreezeSuccess = ({ success }) => {
  const { locale } = React.useContext(GlobalContext)

  const { begin_date: begin, end_date: end, cancel_date: cancel } = success.freeze_period

  const tKey = cancel ? 'cancelled_success' : 'success'

  return (
    <StandardPage
      type={'success'}
      headerI18nKey={`pages.freeze.${tKey}.title`}
      infoI18nKey={`pages.freeze.${tKey}.info`}
      infoI18nVars={{
        begin: dateToLocale(begin, locale),
        end: dateToLocale(end, locale),
      }}
    />
  )
}

FreezeSuccess.propTypes = {
  success: PropTypes.object.isRequired,
}

export default FreezeSuccess
