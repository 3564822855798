import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext } from 'components/context'

const MobileCrumb = ({ previousPath, previousPage }) => {
  const { t } = React.useContext(GlobalContext)

  const page = previousPage ? t(`pages.${previousPage}.title`) : t('mypage.title')

  return (
    <p className={'hidden desktop'}>
      <Crumb
        path={previousPath}
        txt={t('navigation.breadcrumb-mobile', { page: page })}
      />
    </p>
  )
}

MobileCrumb.propTypes = {
  previousPath: PropTypes.string.isRequired,
  previousPage: PropTypes.string,
}

const IGNORED = [
  'pt-package',
]

const BreadCrumbs = ({ location }) => {
  let parts = location.pathname.split('/')

  // The cancellation process have been extended with nested path to support
  // multiple steps. Filter out the nested paths to keep the breadcrumb simple.
  parts = parts.filter(p => !['tips', 'missing-out-on', 'confirm'].includes(p))

  let currentPage = parts.pop()

  if (IGNORED.indexOf(currentPage) > -1) {
    return null
  }

  const previousPath = parts.join('/')
  let previousPage = parts[parts.length - 1]

  // Just a little hack to pretend that /payment/payment-method-updated is the same as /payment
  // We redirect to this after updating creditcard
  if (previousPage === 'payment' && currentPage === 'payment-method-updated') {
    currentPage = parts.pop()
    previousPage = parts[parts.length - 1]
  }

  // Remove the first one that is just an empty string
  parts.shift()

  return (
    <>
      <DesktopCrumbs {...{ parts, currentPage }} />
      <MobileCrumb {...{ previousPath, previousPage }} />
    </>
  )
}

BreadCrumbs.propTypes = {
  location: PropTypes.object.isRequired,
}

const Crumb = ({ path, txt }) =>
  <span>
    <button className={'link-button back'}>
      <Link to={path}>
        <span className={'hidden desktop'}>&lsaquo;&nbsp;</span>
        { txt }
      </Link>
    </button>
    <span className={'hidden phone'}>&nbsp;&rsaquo;&nbsp;</span>
  </span>

Crumb.propTypes = {
  path: PropTypes.string.isRequired,
  txt: PropTypes.string.isRequired,
}

const DesktopCrumbs = ({ parts, currentPage }) => {
  const { t } = React.useContext(GlobalContext)

  const crumb = (part, partIndex, parts) => {
    const path = ['', ...parts.slice(0, partIndex + 1)].join('/')

    // TODO: Use Array with translations keys while waiting for the preferred translation to get done
    return (
      <Crumb
        key={path}
        path={path}
        txt={ t([`pages.${part}.title`, 'pages.payment.credit_card.update_link']) }
      />
    )
  }

  // TODO: Use Array with translations keys while waiting for the preferred translation to get done
  return (
    <p className={'hidden phone'}>
      <Crumb
        path={'/'}
        txt={ t('mypage.title') }
      />
      { parts.map(crumb) }{t([`pages.${currentPage}.title`, 'pages.payment.credit_card.update_link'])}
    </p>
  )
}

DesktopCrumbs.propTypes = {
  parts: PropTypes.array.isRequired,
  currentPage: PropTypes.string.isRequired,
}

export default BreadCrumbs
