import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Redirect } from 'react-router-dom'
import { GlobalContext, UserContext } from 'components/context'
import { FreezeError, FreezeForm, FreezeSuccess } from './components'
import { centToDecimal, dateToLocale } from 'helpers'
import { useLogEvent } from 'components/hooks'

const invalidStatus = (policy, membership) => {
  // Allow members in_freeze to update the current freeze period
  if (membership.status === 'in_freeze') {
    return false
  }

  return !policy.required_membership_status.includes(membership.status)
}

const Freeze = ({ setError, ...rest }) => {
  const { t, T, locale } = React.useContext(GlobalContext)
  const { get, membership } = React.useContext(UserContext)
  const [policy, setPolicy] = React.useState()

  React.useEffect(() => {
    console.log('fetch policy')
    get('freeze_policy')
      .then(res => setPolicy(res))
      .catch(err => {
        console.error('err', err)
        setError(err)
      })
  }, [get, setError])

  if (!policy || !membership) return null

  console.debug('membership:', membership)
  console.debug('policy:', policy)

  const { scheduled_freeze: scheduledFreeze } = membership
  const { current_freeze: currentFreeze } = policy.membership

  if (invalidStatus(policy, membership)) {
    // Just in case someone navigates directly to the route without a valid status
    // TODO: This causes a React warning, consider refactoring:
    //  Can't perform a React state update on an unmounted component..
    return <Redirect to={'/membership'}/>
  }

  const mockDates = (times = 6, offset = 0) => {
    return Array(times).fill().map((_, i) =>
      moment()
        .add(offset + i + 1, 'months')
        .startOf('month')
        .format('YYYY-MM-DD'),
    )
  }

  const UpdateFreezeInfo = () => {
    const fp = scheduledFreeze || currentFreeze

    if (!fp) return null

    const tKey = scheduledFreeze ? 'pages.freeze.scheduled_freeze_info' : 'pages.freeze.active_freeze_info'

    return (
      <p>
        <T
          t={tKey}
          vars={{
            begin: dateToLocale(fp.begin_date, locale),
            end: dateToLocale(fp.end_date, locale),
          }}
        />
      </p>
    )
  }

  // TODO: Add different text to update a scheduled freeze period
  return (
    <div id={'freeze'}>
      <h1>{ t('pages.freeze.title') }</h1>
      <p>
        <T
          t={'pages.freeze.info'}
          vars={{
            max_months: policy.max_months,
            monthly_fee: centToDecimal(policy.monthly_fee),
          }}
        />
      </p>
      <UpdateFreezeInfo />
      <FreezeForm
        policy={policy}
        startDates={mockDates(12)}
        scheduledFreeze={scheduledFreeze || currentFreeze}
        {...rest}
      />
    </div>
  )
}

Freeze.propTypes = {
  onFreeze: PropTypes.func.isRequired,
  setError: PropTypes.func.isRequired,
}

const FreezeWrapper = () => {
  const { apiResource } = React.useContext(UserContext)
  const [success, setSuccess] = React.useState(null)
  const [error, setError] = React.useState(null)
  useLogEvent('view_freeze')

  const apiRequest = (type, body, id = null) => {
    return apiResource({ type: type, subRoute: 'freeze_periods', body: body, id: id })
      .then(res => setSuccess(res))
      .catch(err => {
        err.method = type
        setError(err)
      })
  }

  const goBack = () => {
    setSuccess(null)
    setError(null)
  }

  const onFreeze = (freezePeriod) => {
    if (freezePeriod.id) {
      apiRequest('PATCH', { freeze_period: freezePeriod }, freezePeriod.id)
    } else {
      apiRequest('POST', { freeze_period: freezePeriod })
    }
  }

  if (error) return <FreezeError error={error} goBack={goBack} />
  if (success) return <FreezeSuccess success={success} />

  return <Freeze onFreeze={onFreeze} setError={setError} />
}

export default FreezeWrapper
