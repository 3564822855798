import React from 'react'
import PropTypes from 'prop-types'

const ErrorIcon = ({ color, title, ...rest }) => (
  <svg width='1em' height='1em' viewBox='0 0 20 20' {...rest}>
    <defs>
      <path
        d='M12 22a.997.997 0 0 1-.707-.293l-9-9a.999.999 0 0 1 0-1.414l9-9a.999.999 0 0 1 1.414 0l9 9a.999.999 0 0 1 0 1.414l-9 9A.993.993 0 0 1 12 22zM4.414 12L12 4.415 19.586 12 12 19.586 4.414 12zM11 8v6h2V8h-2zm1 9.25a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z'
        id='prefix__a'
      />
    </defs>
    <g transform='translate(-2 -2)' fill='none' fillRule='evenodd'>
      <title>{title}</title>
      <path d='M0 0h24v24H0z' />
      <mask id='error_prefix__b' fill='#fff'>
        <use xlinkHref='#prefix__a' />
      </mask>
      <g mask='url(#error_prefix__b)' fill={color}>
        <path d='M0 0h24v24H0z' />
      </g>
    </g>
  </svg>
)

ErrorIcon.propTypes = {
  color: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default ErrorIcon
