import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext } from 'components/context'

const ModalVerification = ({ isOpen, onClose, onConfirm, verify }) => {
  const { t } = React.useContext(GlobalContext)
  const [code, setCode] = React.useState('')

  if (!isOpen) {
    return null
  }

  const handleConfirm = (inputValue) => {
    onConfirm(code)
    setCode('')
  }

  const handleCancel = (isValid = false) => {
    onClose()
  }

  const handleChange = (e) => {
    setCode(e.target.value)
  }

  return (
    <div className='modal-overlay'>
      <div className='modal-content'>
        <label className='label'>
          {t('pages.profile.verification.title')}
          <div className='text'>{t('pages.profile.verification.text')}</div>
          <input type='text' name='code' required onChange={handleChange} />
        </label>
        <div className='modal-buttons'>
          <button onClick={handleCancel} className='cancel-button'>Cancel</button>
          <button onClick={handleConfirm} className='confirm-button'>Confirm</button>
        </div>
      </div>
    </div>
  )
}

ModalVerification.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  verify: PropTypes.object,
}

export default ModalVerification
