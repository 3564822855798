/*
 * The maintainer hereby admits that how the request for GDPR opt-in is intended to work
 * is not understood at all.
 *
 * Re-factoring left for another day.
 *
 * Maintainer, June 9th 2022
 */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { GlobalContext, UserContext } from 'components/context'
import { SmallerTextActionButton, SmallerTextSecondaryActionButton } from 'components/Buttons'
import { dateToLocale } from 'helpers'
import { StandardErrorPage } from 'components/DefaultPages'
import { useLogEvent } from 'components/hooks'
import Success from './Success'

const CancellationGDPR = ({ cancelMembership }) => {
  useLogEvent('view_cancellation_confirmation')

  const { t } = React.useContext(GlobalContext)
  const [gdprOptIn, setGdprOptIn] = React.useState(null)
  const [disabled, setDisabled] = React.useState(true)

  const onChange = (event) => {
    setGdprOptIn(event.target.value)
    setDisabled(false)
  }

  const onSubmit = () => cancelMembership(gdprOptIn)

  return (
    <div>
      <p>
        { t('pages.cancellation.gdpr.info')}
      </p>
      <form onChange={onChange} onSubmit={onSubmit}>
        <label>
          <input type={'radio'} id={'yes'} name={'yes'} value={true} />
          { t('pages.cancellation.gdpr.options.yes') }
        </label>
        <label>
          <input type={'radio'} id={'no'} name={'yes'} value={false} />
          { t('pages.cancellation.gdpr.options.no') }
        </label>
      </form>
      <SmallerTextActionButton onClick={onSubmit} disabled={disabled}>
        { t('pages.cancellation.cancel_button') }
      </SmallerTextActionButton>
    </div>
  )
}

CancellationGDPR.propTypes = {
  cancelMembership: PropTypes.func.isRequired,
}

const ConfirmCancellationView = ({ endDate, cancelMembership }) => {
  useLogEvent('view_cancellation_confirmation')
  const { t, locale } = React.useContext(GlobalContext)
  return (
    <div>
      <h1>{ t('pages.cancellation.confirm.title') }</h1>

      <p
        dangerouslySetInnerHTML={{
          __html: t('pages.cancellation.confirm.info', { end_date: dateToLocale(endDate, locale) }),
        }}
      />

      <Link to={'/membership'}>
        <SmallerTextActionButton>
          { t('pages.cancellation.confirm.no_button') }
        </SmallerTextActionButton>
      </Link>
      <SmallerTextSecondaryActionButton onClick={cancelMembership}>
        { t('pages.cancellation.confirm.cancel_button') }
      </SmallerTextSecondaryActionButton>
    </div>
  )
}

ConfirmCancellationView.propTypes = {
  cancelMembership: PropTypes.func.isRequired,
  endDate: PropTypes.object.isRequired,
}

const ConfirmCancellation = () => {
  const { t } = React.useContext(GlobalContext)
  const { membership, get, post } = React.useContext(UserContext)
  const [gdpr, setGDPR] = React.useState(false)
  const [policy, setPolicy] = React.useState()
  const [error, setError] = React.useState(null)
  const [success, setSuccess] = React.useState(null)

  React.useEffect(() => {
    get('cancellation_policy')
      .then(res => setPolicy(res))
      .catch(err => {
        console.error('policy:', err)
        setError(err)
      })
  }, [get])

  const cancelMembership = (gdprOptIn = null) => {
    // For some reason gdprOptIn is a class when not provided,
    // so we explicitly check if it is a boolean
    const body = typeof gdprOptIn === 'boolean' ? { gdpr_opt_in: gdprOptIn } : null
    post('cancellations', body)
      .then(res => setSuccess(res))
      .catch(err => setError(err))
  }

  if (!policy) return null
  if (!membership) return null

  const endDate = policy.membership.calculated_end_date

  const cancelMembershipFunction = t('pages.cancellation.gdpr.info') !== 'pages.cancellation.gdpr.info' ? cancelMembership : () => setGDPR(true)

  const goBack = () => {
    setSuccess(null)
    setError(null)
  }

  if (error) return <StandardErrorPage error={error} goBack={goBack} />

  if (success) {
    return <Success endDate={success.end_date} />
  }

  if (gdpr) {
    return (
      <CancellationGDPR
        cancelMembership={cancelMembership}
      />
    )
  }

  return (
    <ConfirmCancellationView
      endDate={endDate}
      cancelMembership={cancelMembershipFunction}
    />
  )
}

ConfirmCancellation.propTypes = {
  setError: PropTypes.func.isRequired,
  policy: PropTypes.object,
}

export default ConfirmCancellation

export {
  ConfirmCancellationView,
}
