import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import LoginForm from './components/LoginForm'
import { GlobalContext } from 'components/context'

const invalidCredentials = ({ status, reason }) => {
  if (!status) return false

  return status === 404 || reason === 'Error: Password check failed'
}

const LoginError = ({ error, t }) => {
  if (!error) return null

  const tKey = invalidCredentials(error) ? 'invalid' : 'default'

  return (
    <div className={'error'}>
      { t(`forms.login.error.${tKey}`) }
    </div>
  )
}

LoginError.propTypes = {
  error: PropTypes.object,
  t: PropTypes.func.isRequired,
}

const Login = () => {
  const { checkCredentials, t } = useContext(GlobalContext)
  const [error, setError] = React.useState(null)

  return (
    <div className={'container'}>
      <LoginError error={error} t={t} />
      <h1>{ t('forms.login.title') }</h1>
      <LoginForm {...{ checkCredentials, setError, t }} />
    </div>
  )
}

export default Login
