import React from 'react'
import PropTypes from 'prop-types'
import { dateToLocale } from 'helpers'
import { GlobalContext } from 'components/context'

const DatePeriod = ({ period }) => {
  const { locale } = React.useContext(GlobalContext)

  if (!period) return <>-</>

  return (
    <>
      { dateToLocale(period.from, locale) }
      &nbsp;-&nbsp;
      { dateToLocale(period.to, locale) }
    </>
  )
}

DatePeriod.propTypes = {
  period: PropTypes.object,
}

const FormatedDate = ({ date }) => {
  const { locale } = React.useContext(GlobalContext)

  if (!date) return null

  return (
    <>
      { dateToLocale(date, locale) }
    </>
  )
}

FormatedDate.propTypes = {
  date: PropTypes.string,
}

export {
  FormatedDate,
  DatePeriod,
}
