import nbNo from './nb-NO'
import deCh from './de-CH'
import enCh from './en-CH'
import frCh from './fr-CH'
import deDe from './de-DE'
import enDe from './en-DE'
import deAt from './de-AT'
import enAt from './en-AT'

export default {
  'nb-NO': nbNo,
  'de-CH': deCh,
  'en-CH': enCh,
  'fr-CH': frCh,
  'de-DE': deDe,
  'en-DE': enDe,
  'de-AT': deAt,
  'en-AT': enAt,
  default: deDe,
}
