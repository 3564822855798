import React from 'react'
import { GlobalContext, UserContext } from 'components/context'
import { useLogEvent } from 'components/hooks'

const plainTextToHtml = (txt) =>
  txt.replace(/\n/g, '<br />')

const Referral = () => {
  const { t } = React.useContext(GlobalContext)
  const { get, membership } = React.useContext(UserContext)
  const [referral, setReferral] = React.useState()
  useLogEvent('view_referral')

  React.useEffect(() => {
    console.debug('fetching referral')
    get('referral')
      .then(res => setReferral(res))
      .catch(error => console.error('error', error))
  }, [get])

  if (!membership || !referral) return null

  return (
    <div id={'referral'}>
      <h1>{ t('pages.referral.title') }</h1>
      <div className={'referral-code full-width block'}>
        <h2>{ t('pages.referral.code_title') }</h2>
        <div className={'code'}>
          { membership.referral_code }
        </div>
      </div>
      <p
        dangerouslySetInnerHTML={{ __html: plainTextToHtml(referral.referrer_presentation) }}
      />
    </div>
  )
}

export default Referral
