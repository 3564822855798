import React from 'react'
import { UserContext } from '../context'

const useLogEvent = (eventObjectOrName, ready = true) => {
  const { logEvent } = React.useContext(UserContext)
  const [logged, setLogged] = React.useState(false)

  React.useEffect(() => {
    if (!ready) return
    if (logged) return

    logEvent(eventObjectOrName)
      .then(res => setLogged(true))
      .catch(console.error)
  }, [eventObjectOrName, ready, logged, setLogged, logEvent])
}

export {
  useLogEvent,
}
