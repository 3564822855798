import React from 'react'
import PropTypes from 'prop-types'
import { StandardPage } from './index'
import { dateToLocale } from '../../helpers'
import { GlobalContext } from '../context'
import i18n from '../context/global/i18n'

const localizeVars = (vars, t, locale) => {
  const withTranslation = {}
  Object.keys(vars).forEach(key => {
    switch (key) {
      case 'status': {
        withTranslation[key] = `<strong>${t(`status.${vars[key]}`)}</strong>`
        break
      }
      case 'begin_date':
      case 'end_date': {
        withTranslation[key] = dateToLocale(vars[key], locale)
        break
      }
      default: {
        withTranslation[key] = vars[key]
      }
    }
  })

  return withTranslation
}

const errorToText = (error, t, locale) => {
  let vars = error.context || {}
  vars.message = error.message

  if (Array.isArray(error.context)) {
    vars = error.context[0]
  }

  return t(`errors.codes.${error.type}`, localizeVars(vars, t, locale))
}

const Errors = ({ errors }) => {
  const { t, locale } = React.useContext(GlobalContext)

  if (!Array.isArray(errors)) return null
  if (errors.length < 1) return null

  const errorsWithTranslation = errors
    .filter(e => i18n.exists(`errors.codes.${e.type}`, { fallbackLng: 'none' }))

  if (errorsWithTranslation.length < 1) return null

  return (
    <div>
      <h2>{ t('errors.codes.header') }</h2>
      <ul>
        {
          errorsWithTranslation.map(e =>
            <li
              key={e.type}
              dangerouslySetInnerHTML={{ __html: errorToText(e, t, locale) }}
            />,
          )
        }
      </ul>
    </div>
  )
}

Errors.propTypes = {
  errors: PropTypes.array,
}

// TODO: Remove when all routes are implemented in API
const NotImplemented = ({ error }) => {
  if (error.status !== 404) return null

  // Activate RFID might return 404
  if (!error.errors) return null

  return (
    <div style={{ backgroundColor: 'red', color: 'white', padding: '20px' }}>
      <h1>Not implemented</h1>
      <h2>This feature is not yet implemented in the API.</h2>
      <br />
      { error.method } { error.errors[0].url }
    </div>
  )
}

NotImplemented.propTypes = {
  error: PropTypes.object,
}

const StandardErrorPage = ({ children, goBack, error }) => {
  return (
    <StandardPage
      type={'error'}
      headerI18nKey={'errors.default.title'}
      infoI18nKey={'errors.default.info'}
      goBack={goBack}
    >
      { children }
      <Errors errors={error.errors}/>
      <NotImplemented error={error} />
    </StandardPage>
  )
}

StandardErrorPage.propTypes = {
  children: PropTypes.any,
  goBack: PropTypes.func.isRequired,
  error: PropTypes.object.isRequired,
}

export default StandardErrorPage
