import React from 'react'
import PropTypes from 'prop-types'
import { GlobalContext, UserContext } from 'components/context'
import { DatePeriod, FormatedDate } from 'components/Dates'
import Icon from 'components/icons'
import { amountToCurrency } from 'helpers'

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/NumberFormat
// https://www.iana.org/assignments/language-subtag-registry/language-subtag-registry
const Amount = ({ amount, currency }) => {
  const { locale } = React.useContext(GlobalContext)

  return (
    <>{ amountToCurrency(amount, locale, currency) }</>
  )
}

Amount.propTypes = {
  amount: PropTypes.number.isRequired,
  currency: PropTypes.oneOf(['NOK', 'EUR', 'CHF']).isRequired,
}

const DeclinedIcon = ({ invoice }) => {
  if (invoice.status !== 'declined') return null

  return (
    <>
      &nbsp;<Icon title="Unpaid" type={'error'} style={{ height: '14px' }} />
    </>
  )
}

DeclinedIcon.propTypes = {
  invoice: PropTypes.object.isRequired,
}

const UnpaidInvoiceNotice = ({ invoices }) => {
  const unpaidInvoices = invoices.filter((invoice) => invoice.status === 'declined')

  return (unpaidInvoices.length === 0) ? (<></>) : (
    <>
      <p className="unpaid" title="Notification">You have {unpaidInvoices.length} unpaid {(unpaidInvoices.length === 1) ? 'invoice' : 'invoices'}</p>
    </>
  )
}

UnpaidInvoiceNotice.propTypes = {
  invoices: PropTypes.array.isRequired,
}

const paidClass = (invoice) => {
  return (invoice.status === 'declined') ? 'unpaid' : 'paid'
}

const PreviousInvoices = () => {
  const { t } = React.useContext(GlobalContext)
  const { get } = React.useContext(UserContext)
  const [invoices, setInvoices] = React.useState([])

  React.useEffect(() => {
    get('invoices').then(res => {
      setInvoices(res)
    })
  }, [get])

  console.log('payment invoices:', invoices)

  return (
    <div style={{ marginTop: '40px' }}>
      <h2>{ t('pages.payment.invoices.title') }</h2>
      <UnpaidInvoiceNotice invoices={invoices} />
      <table>
        <thead>
          <tr>
            <th>{ t('pages.payment.invoices.date') }</th>
            <th>{ t('pages.payment.invoices.amount') }</th>
            <th>{ t('pages.payment.invoices.period') }</th>
            <th>PDF</th>
          </tr>
        </thead>
        <tbody>
          { invoices.map((i) => (
            <tr key={i.invoice_number} className={paidClass(i)}>
              <td><FormatedDate date={i.invoice_date} /></td>
              <td><Amount {...i} /><DeclinedIcon invoice={i} /></td>
              <td><DatePeriod period={i.period} /></td>
              <td>
                <a href={'/invoice/' + i.id} target={'_blank'} rel={'noopener noreferrer'}>
                  <Icon type={'offers'} />
                </a>
              </td>
            </tr>
          )) }
        </tbody>
      </table>
    </div>
  )
}

export default PreviousInvoices
