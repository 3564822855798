import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ActionButton } from 'components/Buttons'
import { GlobalContext } from 'components/context'
import { useLogEvent } from 'components/hooks'

// Not using StandardErrorPage to display a more direct
// and easier to understand error message
const ValidationError = ({ error }) => {
  useLogEvent(`view_cancellation_${error.type}_error`)

  const { t } = React.useContext(GlobalContext)

  return (
    <div>
      <h1>{ t(`pages.cancellation.${error.type}.title`) }</h1>
      <p>{ t(`pages.cancellation.${error.type}.body`) }</p>
      <Link to={'/'}>
        <ActionButton>
          { t(`pages.cancellation.${error.type}.back`) }
        </ActionButton>
      </Link>
    </div>
  )
}

ValidationError.propTypes = {
  error: PropTypes.object.isRequired,
}

export default ValidationError
